import { IAction } from '../shared/common.interfaces';
import { TreeNode } from '../../../core/interface/core.interface';

export class GeneralAction {
  static key = 'GENERAL';

  static GET_HEALY_MEMBERS = `[${ GeneralAction.key }] Get Healy members`;
  static GET_HEALY_MEMBERS_SUCCESS = `[${ GeneralAction.key }] Get Healy members success`;
  static GET_HEALY_MEMBERS_FAILED = `[${ GeneralAction.key }] Get Healy members failed`;

  static UPLOAD_DOCUMENT = `[${ GeneralAction.key }] Upload document`;
  static UPLOAD_DOCUMENT_SUCCESS = `[${ GeneralAction.key }] Upload document success`;
  static UPLOAD_DOCUMENT_FAILED = `[${ GeneralAction.key }] Upload document failed`;

  static GET_DOCUMENTS = `[${ GeneralAction.key }] Get documents`;
  static GET_DOCUMENTS_SUCCESS = `[${ GeneralAction.key }] Get documents success`;
  static GET_DOCUMENTS_FAILED = `[${ GeneralAction.key }] Get documents failed`;

  static DATABASE_CONNECTION = `[${GeneralAction.key}] Database connection`;
  static DATABASE_CONNECTION_SUCCESS = `[${GeneralAction.key}] Database connection success`;
  static DATABASE_CONNECTION_FAILED = `[${GeneralAction.key}] Database connection failed`;

  static RUN_CONNECTOR = `[${GeneralAction.key}] Run connector`;
  static RUN_CONNECTOR_SUCCESS = `[${GeneralAction.key}] Run connector success`;
  static RUN_CONNECTOR_FAILED = `[${GeneralAction.key}] Run connector failed`;

  static AUTHORISE_ENTRY = `[${GeneralAction.key}] Authorise entry`;
  static AUTHORISE_ENTRY_SUCCESS = `[${GeneralAction.key}] Authorise entry success`;
  static AUTHORISE_ENTRY_FAILED = `[${GeneralAction.key}] Authorise entry failed`;

  static REQUEST = `[${GeneralAction.key}] Request`;
  static REQUEST_SUCCESS = `[${GeneralAction.key}] Request success`;
  static REQUEST_FAILED = `[${GeneralAction.key}] Request failed`;

  public getHealyMembers(start: number, count: number, search?: string): IAction {
    return {
      type: GeneralAction.GET_HEALY_MEMBERS,
      payload: {
        data: {
          start: start,
          count: count,
          search: search
        }
      }
    };
  }

  public getHealyMembersSuccess(response) {
    return {
      type: GeneralAction.GET_HEALY_MEMBERS_SUCCESS,
      payload: response
    };
  }

  public getHealyMembersFailed() {
    return {
      type: GeneralAction.GET_HEALY_MEMBERS_FAILED,
      payload: null
    };
  }

  public uploadDocument(requestId: string, files: FileList): IAction {
    /* Get file */
    const file = files[0];
    /* Build up form data */
    const formData = new FormData();
    formData.append('file', file, file.name);
    return {
      type: GeneralAction.UPLOAD_DOCUMENT,
      payload: {
        id: requestId,
        data: formData
      }
    };
  }

  public uploadDocumentSuccess(requestId: string, response: any) {
    return {
      type: GeneralAction.UPLOAD_DOCUMENT_SUCCESS,
      payload: {
        request: requestId,
        response
      }
    };
  }

  public uploadDocumentFailed(requestId: string) {
    return {
      type: GeneralAction.UPLOAD_DOCUMENT_FAILED,
      payload: {
        request: requestId
      }
    };
  }

  public getDocuments(requestId: string, keys: string[]): IAction {
    return {
      type: GeneralAction.GET_DOCUMENTS,
      payload: {
        id: requestId,
        data: {
          keys
        }
      }
    };
  }

  public getDocumentsSuccess(requestId: string, response: any) {
    return {
      type: GeneralAction.GET_DOCUMENTS_SUCCESS,
      payload: {
        request: requestId,
        response
      }
    };
  }

  public getDocumentsFailed(requestId: string) {
    return {
      type: GeneralAction.GET_DOCUMENTS_FAILED,
      payload: {
        request: requestId
      }
    };
  }

  public databaseConnection(requestId: string, json: string) {
    return {
      type: GeneralAction.DATABASE_CONNECTION,
      payload: {
        id: requestId,
        data: json
      }
    };
  }

  public databaseConnectionSuccess(requestId: string, payload: any) {
    return {
      type: GeneralAction.DATABASE_CONNECTION_SUCCESS,
      payload: {
        request: requestId,
        response: payload
      }
    };
  }

  public databaseConnectionFailed(requestId: string, error) {
    return {
      type: GeneralAction.DATABASE_CONNECTION_FAILED,
      payload: {
        request: requestId,
        response: error
      }
    };
  }

  public runConnector(requestId: string, json: string) {
    return {
      type: GeneralAction.RUN_CONNECTOR,
      payload: {
        id: requestId,
        data: json
      }
    };
  }

  public runConnectorSuccess(requestId: string, payload: any) {
    return {
      type: GeneralAction.RUN_CONNECTOR_SUCCESS,
      payload: {
        request: requestId,
        response: payload
      }
    };
  }

  public runConnectorFailed(requestId: string, error) {
    return {
      type: GeneralAction.RUN_CONNECTOR_FAILED,
      payload: {
        request: requestId,
        response: error
      }
    };
  }

  public authoriseEntry(requestId: string, domain: string, json: string) {
    return {
      type: GeneralAction.AUTHORISE_ENTRY,
      payload: {
        id: requestId,
        data: json,
        domain
      }
    };
  }

  public authoriseEntrySuccess(requestId: string, payload: any) {
    return {
      type: GeneralAction.AUTHORISE_ENTRY_SUCCESS,
      payload: {
        request: requestId,
        response: payload
      }
    };
  }

  public authoriseEntryFailed(requestId: string, error) {
    return {
      type: GeneralAction.AUTHORISE_ENTRY_FAILED,
      payload: {
        request: requestId,
        response: error
      }
    };
  }

  public request(data: any) {
    return {
      type: GeneralAction.REQUEST,
      payload: {
        data
      }
    };
  }

  public requestSuccess(payload: any) {
    return {
      type: GeneralAction.REQUEST_SUCCESS,
      payload: {
        response: payload
      }
    };
  }

  public requestFailed(error) {
    return {
      type: GeneralAction.REQUEST_FAILED,
      payload: {
        response: error
      }
    };
  }

}
