import {
  NODES_TYPE_CALCULATION,
  NODES_TYPE_CONDITION,
  NODES_TYPE_CONVERSION,
  NODES_TYPE_NODETYPEGROUPS,
  NODES_TYPE_PERCENTAGE,
  NODES_TYPE_DECIMALS,
  NODES_TYPE_THOUSANDS,
  NODES_TYPE_DATE,
  NODES_TYPE_EXPORT,
  NODES_TYPE_COLOR,
  NODES_TYPE_STYLE,
  NODES_TYPE_APP,
  NODES_TYPE_CHAT,
  NODES_TYPE_UPDATE_PERIPHERY,
  NODES_TYPE_COLLAPSE,
  NODES_TYPE_AGENT,
  NODES_TYPE_PLACEHOLDER,
  NODES_TYPE_SUBJECT,
  NODES_TYPE_TEMPLATE,
  NODES_TYPE_START,
  NODES_TYPE_END, NODES_TYPE_KNOWLEDGE_GRAPH,
  NODES_TYPE_NO_DECIMAL, NODES_TYPE_SEARCH_QUERY, NODES_TYPE_PAGE
} from './../../shared/api/nodes/nodes.models';
import { BehaviorSubject, Observable } from 'rxjs';
import { GridsterItem } from 'angular-gridster2';

import { Businessarea } from '../../shared/api/businessareas';
import { MODEL_TYPE_CCM, MODEL_TYPE_CWM, MODEL_TYPE_DATASOURCE, MODEL_TYPE_DEFAULT, MODEL_TYPE_LIBRARY, MODEL_TYPE_MCM, MODEL_TYPE_NFM, MODEL_TYPE_PROJECTPORTFOLIO, MODEL_TYPE_SCM, MODEL_TYPE_SPLASH, MODEL_TYPE_STRATEGYMAP, MODEL_TYPE_UCIM, ModelCreate } from '../../shared/api/models/models.models';
import { IPayload } from '../../shared/api/shared';
import { NodeCreate } from '../../shared/api/nodes';
import { RelationshipCreate } from '../../shared/api/relationships';

import {
  NODES_TYPE_COEFFICIENT,
  NODES_TYPE_ACTION,
  NODES_TYPE_ACTIONBAR,
  NODES_TYPE_ACTIONGROUP,
  NODES_TYPE_ADD,
  NODES_TYPE_ADD_CHILD,
  NODES_TYPE_ADD_ELEMENT,
  NODES_TYPE_AI,
  NODES_TYPE_ALLOWED,
  NODES_TYPE_ANALYSE,
  NODES_TYPE_ANALYSE_STRUCTURE,
  NODES_TYPE_ANALYSEFOLDER,
  NODES_TYPE_ASSET_GROUP,
  NODES_TYPE_ASSET_MACHINE,
  NODES_TYPE_ASSET_MODULE,
  NODES_TYPE_ASSET_POSITION,
  NODES_TYPE_AXIS,
  NODES_TYPE_BADWORD,
  NODES_TYPE_BUSINESSAREA,
  NODES_TYPE_BY,
  NODES_TYPE_CAPABILITY,
  NODES_TYPE_CAPACITY,
  NODES_TYPE_CATEGORY,
  NODES_TYPE_CATEGORYGROUP,
  NODES_TYPE_CCM,
  NODES_TYPE_CENTER,
  NODES_TYPE_CHALLENGE,
  NODES_TYPE_CHILD,
  NODES_TYPE_CLIENT,
  NODES_TYPE_COLORLABELPROVIDER,
  NODES_TYPE_COLUMN,
  NODES_TYPE_COMMENT,
  NODES_TYPE_COMPETITOR,
  NODES_TYPE_CONNECT,
  NODES_TYPE_COUNTERMEASURE,
  NODES_TYPE_CURVED_LINK,
  NODES_TYPE_CWM,
  NODES_TYPE_DATASHEET,
  NODES_TYPE_DATASOURCE,
  NODES_TYPE_DATATARGET,
  NODES_TYPE_DEFAULT,
  NODES_TYPE_DEFAULTVALUES,
  NODES_TYPE_DEFINITION,
  NODES_TYPE_DELETE,
  NODES_TYPE_DELIVERABLE,
  NODES_TYPE_DEMAND,
  NODES_TYPE_DIRECT_CHAIN,
  NODES_TYPE_DISCONNECT,
  NODES_TYPE_DRAG,
  NODES_TYPE_DROP,
  NODES_TYPE_DUPLICATE,
  NODES_TYPE_EDIT,
  NODES_TYPE_ELSE,
  NODES_TYPE_ENTITY,
  NODES_TYPE_FACTSHEET,
  NODES_TYPE_FEASIBILITY_IP,
  NODES_TYPE_FEASIBILITY_TECH,
  NODES_TYPE_FIELD,
  NODES_TYPE_FIELDS,
  NODES_TYPE_FILTERS,
  NODES_TYPE_FOCUS,
  NODES_TYPE_FOOTER_TABS,
  NODES_TYPE_FORM,
  NODES_TYPE_FORM_COLUMN,
  NODES_TYPE_FORM_OPTION,
  NODES_TYPE_FORM_ROW,
  NODES_TYPE_FORM_TAB,
  NODES_TYPE_FULL,
  NODES_TYPE_FUNCTIONS,
  NODES_TYPE_GANTT,
  NODES_TYPE_GAP,
  NODES_TYPE_GLOBALFILTER,
  NODES_TYPE_GLOBALSUM,
  NODES_TYPE_GROUP,
  NODES_TYPE_HIERARCHY,
  NODES_TYPE_HOMEACTION,
  NODES_TYPE_HORIZONTAL,
  NODES_TYPE_HUMANRESOURCE,
  NODES_TYPE_IDEA,
  NODES_TYPE_IF,
  NODES_TYPE_IGNORE,
  NODES_TYPE_INDUSTRY,
  NODES_TYPE_INFRACTION,
  NODES_TYPE_INITIATIVE,
  NODES_TYPE_INSTANCE,
  NODES_TYPE_IPCCLASS,
  NODES_TYPE_IPNAVIGATOR,
  NODES_TYPE_KEYWORD,
  NODES_TYPE_LEGEND,
  NODES_TYPE_LIBRARY,
  NODES_TYPE_LINE,
  NODES_TYPE_LINK,
  NODES_TYPE_MACHINE,
  NODES_TYPE_MACROTREND,
  NODES_TYPE_MANUFACTURING_SLOT,
  NODES_TYPE_MARKET,
  NODES_TYPE_MCM,
  NODES_TYPE_MEGATREND,
  NODES_TYPE_MEMBER,
  NODES_TYPE_METHODOLOGY,
  NODES_TYPE_MICROTREND,
  NODES_TYPE_MILESTONE,
  NODES_TYPE_MODEL,
  NODES_TYPE_MODULE,
  NODES_TYPE_MODULECONFIGURATION,
  NODES_TYPE_MUSTHAVE,
  NODES_TYPE_MUTATION,
  NODES_TYPE_MY,
  NODES_TYPE_NEXT,
  NODES_TYPE_NODE,
  NODES_TYPE_NODETYPEGROUP,
  NODES_TYPE_OBJECTIVE,
  NODES_TYPE_OPERATION,
  NODES_TYPE_ORGANISATIONALUNIT,
  NODES_TYPE_ORGLEVEL_1,
  NODES_TYPE_ORGLEVEL_2,
  NODES_TYPE_ORGLEVEL_3,
  NODES_TYPE_ORGLEVEL_5,
  NODES_TYPE_ORGLEVEL_6,
  NODES_TYPE_ORGLEVEL_7,
  NODES_TYPE_PAGINATION,
  NODES_TYPE_PARENT,
  NODES_TYPE_PATENT,
  NODES_TYPE_PLACE_CHILDREN_ASIDE,
  NODES_TYPE_PLANTS,
  NODES_TYPE_PROCESS,
  NODES_TYPE_PRODUCT,
  NODES_TYPE_PRODUCTGROUP,
  NODES_TYPE_PROJECT,
  NODES_TYPE_PROJECT_CATEGORY,
  NODES_TYPE_PROJECTPORTFOLIO,
  NODES_TYPE_QUESTION,
  NODES_TYPE_RECEIVER,
  NODES_TYPE_REGION,
  NODES_TYPE_RELATIONSHIP,
  NODES_TYPE_REMAINING,
  NODES_TYPE_REMINDER,
  NODES_TYPE_REQUIREMENT,
  NODES_TYPE_REVERSE,
  NODES_TYPE_ROLLOUT,
  NODES_TYPE_ROUNDED,
  NODES_TYPE_SCM,
  NODES_TYPE_SEARCH,
  NODES_TYPE_SELECT,
  NODES_TYPE_SETTINGS,
  NODES_TYPE_SETUPACTION,
  NODES_TYPE_SKILLS,
  NODES_TYPE_SLOT,
  NODES_TYPE_SMARTACTION,
  NODES_TYPE_SOLUTION,
  NODES_TYPE_SORTING,
  NODES_TYPE_SPLASHSCREEN,
  NODES_TYPE_STANDARD_LINE,
  NODES_TYPE_STEP,
  NODES_TYPE_STRAIGHT_LINKS,
  NODES_TYPE_STRATEGYMAP,
  NODES_TYPE_STRUCTURE,
  NODES_TYPE_SUGGESTIONS,
  NODES_TYPE_SUM,
  NODES_TYPE_TABLEGROUP,
  NODES_TYPE_TABLEGROUPMONTHS,
  NODES_TYPE_TASK,
  NODES_TYPE_TEAM,
  NODES_TYPE_TECHAREA,
  NODES_TYPE_TECHFIELD,
  NODES_TYPE_TEXTBLOCK,
  NODES_TYPE_THEN,
  NODES_TYPE_TOGGLE,
  NODES_TYPE_TRANSFER,
  NODES_TYPE_TREND,
  NODES_TYPE_UCIM,
  NODES_TYPE_UPDATE,
  NODES_TYPE_VALUE,
  NODES_TYPE_VERTICAL,
  NODES_TYPE_WBS_ACTIVITY,
  NODES_TYPE_WBS_PROGRAM,
  NODES_TYPE_WBS_SUBPROJECTS,
  NODES_TYPE_WBS_WORKPACKAGE,
  NODES_TYPE_WELCOME_POWER,
  NODES_TYPE_WELCOME_REGULAR,
  NODES_TYPE_WIDGET,
  NODES_TYPE_WIDGET_FOOTER,
  NODES_TYPE_WIDGET_HEADER,
  NODES_TYPE_WIDGET_ROW,
  NODES_TYPE_WORKFLOW,
  NODES_TYPE_WORKFLOW_LINK,
  NODES_TYPE_WORKFLOW_QUESTIONNAIRE,
  NODES_TYPE_WORKFLOW_QUESTIONNAIRE_TAB,
  NODES_TYPE_PREFILTER,
  NODES_TYPE_VERSION,
  NODES_TYPE_POSITION,
  NODES_TYPE_STANDARD_CONFIGURATION,
  NODES_TYPE_OFFLINE,
  NODES_TYPE_EVENT,
  NODES_TYPE_MACHINE_TYPE,
  NODES_TYPE_LC_STATE,
  NODES_TYPE_SUPPLIER,
  NODES_TYPE_ALTERNATIVE,
  NODES_TYPE_EVENT_CATEGORY,
  NODES_TYPE_LABEL,
  NODES_TYPE_SHARE,
  NODES_TYPE_TREE,
  NODES_TYPE_NOT,
  NODES_TYPE_NOTIFICATIONS,
  NODES_TYPE_PRIVATE,
  NODES_TYPE_TRAVERSER,
  NODES_TYPE_NFM,
  NODES_TYPE_PRODUCT_AREA,
  NODES_TYPE_SEQUENCE,
  NODES_TYPE_ENTRY,
  NODES_TYPE_ANSWER,
  NODES_TYPE_TAG,
  NODES_TYPE_DOCUMENT,
  NODES_TYPE_DATASTORAGE,
  NODES_TYPE_VIDEO,
  NODES_TYPE_PROMPT,
  NODES_TYPE_EXTERNAL_INPUT,
  NODES_TYPE_INPUT,
  NODES_TYPE_ONLY_ONE_STACK
} from '../../shared/api/nodes/nodes.models';
import { EventEmitter } from '@angular/core';
import { Activity } from '../../shared/api/activities';
import { HumanResource } from '../../shared/api/humanresources';
import { Group } from '../../shared/api/groups';
import { DashboardScreenComponent } from '../../screens/dashboard/dashboard.screen.component';
import { Datum } from '../../shared/utilities/datum';
import { FormGroup } from '@angular/forms';
import { Traverser } from '../../services/traverser/traverser';
import { Map } from 'immutable';

export interface SequencerMessage {
  treeNodes: TreeNode[];
  trtMap?: {};
}

export interface TreeActivity {
  /* Tree */
  id: string;
  phantom: boolean;
  calculated: any;
  nodebucket: boolean;
  internalType: string;
  /* Relationships */
  activitable_type: string;
  activitable_id: string;
  humanresources: string;
  instance: string;
  nodeData: string;
  /* Data */
  name: string;
  description: string;
  start: string;
  startDatum: Datum;
  end: string;
  endDatum: Datum;
  startActual: string;
  endActual: string;
  reference: string;
  crossReference: string;
  type: number;
  status: number;
  responsibleId: number;
  percentageComplete: number;
  priority: number;
  milestone: number;
  budget: number;
  budgetPlan: number;
  benefitBudget: number;
  benefitActual: number;
  benefitRemaining: number;
  costBudget: number;
  costActual: number;
  costRemaining: number;
  investBudget: number;
  investActual: number;
  investRemaining: number;
  riskProfileCategory: number;
  riskProfileAssessment: number;
  riskProfileCountermeasures: string;
  time: number;
  timeDescription: string;
  cost: number;
  costDescription: string;
  quality: number;
  qualityDescription: string;
  executive: number;
  executiveDescription: string;
  program: number;
  programDescription: string;
  functional: number;
  functionalDescription: string;
  resource: number;
  resourceDescription: string;
  organizational: number;
  organizationalDescription: string;
  technical: number;
  technicalDescription: string;
  updatedAt: number;
  duplicate_original_id: number;
  sorting: number;
  diffPrevious: any;
  // float_field1_a: number;
  // float_field2_a: number;
  // float_field3_a: number;
  // float_field4_a: number;
  // float_field5_a: number;
  // float_field6_a: number;
  // float_field7_a: number;
  // float_field8_a: number;
  // float_field9_a: number;
  // float_field10_a: number;
  // float_field11_a: number;
  // float_field12_a: number;
  // float_field13_a: number;
  // float_field14_a: number;
  // float_field15_a: number;
  // float_field1_b: number;
  // float_field2_b: number;
  // float_field3_b: number;
  // float_field4_b: number;
  // float_field5_b: number;
  // float_field6_b: number;
  // float_field7_b: number;
  // float_field8_b: number;
  // float_field9_b: number;
  // float_field10_b: number;
  // float_field11_b: number;
  // float_field12_b: number;
  // float_field13_b: number;
  // float_field14_b: number;
  // float_field15_b: number;
  // float_field1_c: number;
  // float_field2_c: number;
  // float_field3_c: number;
  // float_field4_c: number;
  // float_field5_c: number;
  // float_field6_c: number;
  // float_field7_c: number;
  // float_field8_c: number;
  // float_field9_c: number;
  // float_field10_c: number;
  // float_field11_c: number;
  // float_field12_c: number;
  // float_field13_c: number;
  // float_field14_c: number;
  // float_field15_c: number;
  // float_field1_d: number;
  // float_field2_d: number;
  // float_field3_d: number;
  // float_field4_d: number;
  // float_field5_d: number;
  // float_field6_d: number;
  // float_field7_d: number;
  // float_field8_d: number;
  // float_field9_d: number;
  // float_field10_d: number;
  // float_field11_d: number;
  // float_field12_d: number;
  // float_field13_d: number;
  // float_field14_d: number;
  // float_field15_d: number;
}

export interface TreeNode {
  /* Ids */
  id: string;
  dataId: string;
  creationId: string;
  internalType: string;
  internalSorting: number;
  internalIndex: number;
  internalSubLevel: number;
  internalSubLevels: any;
  internalSortingIndex: string;
  /* Tree information */
  parents: TreeNode[];
  parentsWithoutGF: TreeNode[];
  parentsByKey: any;
  parentIds: string[];
  unfilteredParentIds: string[];
  children: TreeNode[];
  childrenWithoutGF: TreeNode[];
  childrenByKey: any;
  childIds: string[];
  unfilteredChildIds: string[];
  filteredChildren: any;
  unfilteredParents: TreeNode[];
  unfilteredChildren: TreeNode[];
  connectedChildren: TreeNode[];
  relatedTreeNode: TreeNode;
  originalParents: TreeNode[];
  originalChildren: TreeNode[];
  activities: TreeActivity[];
  hierarchyTreeNodes: TreeNode[];
  sourceTreeNodes: TreeNode[];
  activityIds: number[];
  treeAnchor: string;
  level: number;
  subLevel: number;
  color: string;
  invertedColor: string;
  visible: any;
  /* Colors */
  colors: string[];
  invertedColors: string[];
  /* Relationships */
  nodedata: string;
  originalId: string;
  modelId: any;
  parentModel: string;
  selectedModel: number;
  subsets: string[];
  sidesteps: string[];
  instance: string;
  templates: string;
  models: string[];
  nodestructures: string[];
  businessarea: string;
  created: string[];
  relatedChildren: number[];
  relatedParents: number[];
  related: number[];
  trtMap: any;
  /* Node structure */
  active: boolean;
  positionX: number;
  autoPositionX: number;
  autoPositionUncrossed: number;
  updatedAt: number;
  isSidestep: boolean;
  isInput: boolean;
  /* Node data */
  name: string;
  reference: string;
  crossReference: string;
  createdAt: number;
  description: string;
  description1: string;
  description2: string;
  description3: string;
  description4: string;
  documentUri: string;
  upload_uri: string;
  status: number;
  responsibleId: number;
  groupId: number;
  targetDate: string | number;
  actualDate: string;
  aggregationKPI: number;
  KPI: number;
  businessBenefit: number;
  commercialStatus: number;
  complexity: number;
  uncertainty: number;
  risk: number;
  sizeType: number;
  budget: number;
  budgetActual: number;
  budgetRemaining: number;
  budgetCalculated: number;
  benefitBudget: number;
  benefitActual: number;
  benefitRemaining: number;
  costBudget: number;
  costActual: number;
  costRemaining: number;
  investBudget: number;
  investActual: number;
  investRemaining: number;
  businesscalculation: string;
  creationDate: number;
  lastUpdated: number;
  currency: string;
  startDate: string;
  actualStartDate: string;
  percentageComplete: number;
  priority: number;
  sorting: number;
  riskProfileCategory: number;
  riskProfileAssessment: number;
  riskProfileCountermeasures: string;
  time: number;
  timeDescription: string;
  cost: number;
  costDescription: string;
  quality: number;
  qualityDescription: string;
  executive: number;
  executiveDescription: string;
  program: number;
  programDescription: string;
  functional: number;
  functionalDescription: string;
  resource: number;
  resourceDescription: string;
  organizational: number;
  organizationalDescription: string;
  technical: number;
  technicalDescription: string;
  storypoints: number;
  costtype: number;
  onreport: boolean;
  nodeType: number;
  linkToParent: string;
  alsoPublishedAs: string;
  cpc: string;
  cpc4: string;
  ipc: string;
  ipc4: string;
  applicants: string;
  techAreas: string;
  techFields: string;
  authorities: string;
  workFlowModel: number;
  workFlowOperation: string;
  workFlowIfClause: string;
  workFlowId: string;
  workFlowFormId: string;
  workFlowFormParams: string;
  workFlowLink: string;
  workFlowTabId: string;
  formId: string;
  formFieldId: string;
  formFieldType: string;
  formFieldControlType: string;
  formFieldDropdownValue: string;
  formFieldEditable: boolean;
  formFieldSortable: boolean;
  formFieldFilterable: boolean;
  formFieldFixed: boolean;
  formFieldSearchable: boolean;
  formFieldWidth: string;
  fullscreen: boolean;
  formFieldBucketId: string;
  formFieldCalculation: string;
  formFieldShape: string;
  formBucket: boolean;
  form_default_value: string | number;
  printable: boolean;
  hideWidget: boolean;
  ai_type: string;
  ai_preset: string;
  structure_duplicate_original_id: number;
  duplicate_original_id: number;
  widget: string;
  icon: string;
  obligatory: boolean;
  dashboardCols: number;
  dashboardRows: number;
  dashboardX: number;
  dashboardY: number;
  fieldConversion: string;
  fieldCreateType: string;
  fieldSkipIfExists: boolean;
  colorLabelProvider: string;
  sync: any;
  /* Additional parameter for in app usage */
  ids: string[]; // in case of multifactsheet
  truncatedName: string | string[][];
  subLevelName: string;
  x: number;
  y: number;
  fx: number;
  fy: number;
  sx: number;
  sy: number;
  selector: string;
  phantom: boolean;
  virtual: boolean;
  virtualChild: boolean;
  hasVirtualChild: boolean;
  placeholder: boolean;
  placeholderId: string;
  filtered: boolean;
  hasChildren: boolean;
  size: number;
  selected: boolean;
  preselected: boolean;
  collapsed: boolean;
  image: any;
  relationship: TreeRelationship;
  dropZones: string[];
  email: string;
  createdAtReadable: string;
  audit: string;
  isSummary: boolean;
  attention: boolean;
  calculatedStart: Datum;
  calculatedEnd: Datum;
  projectDates: any;
  flag: string;
  checkboxValue: boolean;
  validFrom?: Date;
  validUntil?: Date;
  milestone?: boolean;
  ping: boolean;
  valueInBudget: boolean;
  isSourceNode?: boolean;
  hasSourceNode?: boolean;
  customLabel?: string;
  left?: string;
  deletable?: boolean;
  token?: string;
  mergedChildren?: any[];
  mergedParents?: any[];
  numberField1: number;
  numberField2: number;
  numberField3: number;
  numberField4: number;
  numberField5: number;
  numberField6: number;
  numberField7: number;
  numberField8: number;
  numberField9: number;
  numberField10: number;
  textField1: string;
  textField2: string;
  textField3: string;
  textField4: string;
  textField5: string;
  textField6: string;
  textField7: string;
  textField8: string;
  textField9: string;
  textField10: number;
  textField11: string;
  textField12: string;
  textField13: string;
  textField14: string;
  textField15: string;
  textField16: string;
  textField17: string;
  textField18: string;
  textField19: string;
  textField20: string;
  textField21: string;
  textField22: string;
  textField23: string;
  textField24: string;
  textField25: string;
  textField26: string;
  textField27: string;
  textField28: string;
  textField29: string;
  textField30: string;
  textField31: string;
  textField32: string;
  textField33: string;
  textField34: string;
  textField35: string;
  textField36: string;
  textField37: string;
  textField38: string;
  textField39: string;
  textField40: string;
  formFieldValidation: string;
  formFieldValidationMessage: string;
  bestCase: number;
  differentModel: boolean;
  aiService: string;
  externalInput: string;
  code: string;
  yearCalendar: string[];
  yearCalendarValueA: any;
  yearCalendarValueB: any;
  yearCalendarColor: any;
  hideInTable: boolean;
  jsonObj1: any;
  jsonObj2: any;
  next: any;
  hierarchyIndex: number;
  showHierarchy: boolean;
  formGroup: FormGroup;
  highlight: string;
  tags: string[];
  additionalChildren: TreeNode[];
  additionalParents: TreeNode[];
  virtualChildren: TreeNode[];
  virtualRelationships: TreeRelationship[];
  virtualRelationship: TreeRelationship;
  categoryHierarchyPosition: string;
  stackNodeIds: string[];
  strictStackNodeIds: {};
  relatedTo?: string;
  type?: number;
  onstrategyradar?: number;
  parentsRelationshipId: any;
  childrenRelationshipId: any;
  doNotSendOnEnter: boolean;
  classes: string[];
  doNotShowProject: boolean;
  disabled: boolean;
  isCurrent: boolean;
  hasNext: boolean;
  hasPrevious: boolean;
  isDuration: boolean;
  durationShift: number;
  resize: 'start' | 'end';
  resizeInitial: any;
  explanation: string;
  explanationField: string;
  value: any;
  floatField1: number;
  floatField2: number;
  floatField3: number;
  floatField4: number;
  floatField5: number;
  floatField6: number;
  floatField7: number;
  floatField8: number;
  floatField9: number;
  floatField10: number;
  showOnlyOnMobile: boolean;
  hideOnMobile: boolean;
  partOfTRT: boolean;
  first_name: string;
  last_name: string;
  foreign_id: number;
  entryNodes: any;
  aggregationChildren: TreeNode[];
  aggregationType: string;
  aggregationFormNode: TreeNode;
}

export interface CoreAudit {
  id: string;
  event: string;
  before?: any;
  delta?: any;
  after?: any;
  type?: string;
  elementId: string;
  userId?: string;
  responsibleId?: string;
  updatedAt: Datum;
  readableUpdatedAt?: string;
}

export const CoreNodeTypes: CoreNodeType[] = [
  { key: 0, label: '', color: '#999999' },
  { key: NODES_TYPE_INITIATIVE, label: 'NODE.DATASHEET.FIELDS.TYPE.INITIATIVE', color: '#44256B' },
  { key: NODES_TYPE_QUESTION, label: 'NODE.DATASHEET.FIELDS.TYPE.QUESTION', color: '#999999' },
  { key: NODES_TYPE_PROJECT, label: 'NODE.DATASHEET.FIELDS.TYPE.PROJECT', color: '#26876D' },
  { key: NODES_TYPE_FEASIBILITY_TECH, label: 'NODE.DATASHEET.FIELDS.TYPE.TECH', color: '#999999' },
  { key: NODES_TYPE_FEASIBILITY_IP, label: 'NODE.DATASHEET.FIELDS.TYPE.IP', color: '#999999' },
  { key: NODES_TYPE_REQUIREMENT, label: 'NODE.DATASHEET.FIELDS.TYPE.REQUIREMENT', color: '#79B8F4' },
  { key: NODES_TYPE_SOLUTION, label: 'NODE.DATASHEET.FIELDS.TYPE.SOLUTION', color: '#F6DB79' },
  { key: NODES_TYPE_LIBRARY, label: 'NODE.DATASHEET.FIELDS.TYPE.LIBRARY', color: '#999999' },
  { key: NODES_TYPE_DATASOURCE, label: 'NODE.DATASHEET.FIELDS.TYPE.DATASOURCE', color: '#999999' },
  { key: NODES_TYPE_TREND, label: 'NODE.DATASHEET.FIELDS.TYPE.TRENDS', color: '#999999' },
  { key: NODES_TYPE_IPCCLASS, label: 'NODE.DATASHEET.FIELDS.TYPE.IPCCLASS', color: '#999999' },
  { key: NODES_TYPE_PATENT, label: 'NODE.DATASHEET.FIELDS.TYPE.PATENT', color: '#23806f' },
  { key: NODES_TYPE_CLIENT, label: 'NODE.DATASHEET.FIELDS.TYPE.CLIENT', color: '#041729' },
  { key: NODES_TYPE_COMPETITOR, label: 'NODE.DATASHEET.FIELDS.TYPE.COMPETITOR', color: '#999999' },
  { key: NODES_TYPE_MARKET, label: 'NODE.DATASHEET.FIELDS.TYPE.MARKET', color: '#2CA4BB' },
  { key: NODES_TYPE_DEFINITION, label: 'NODE.DATASHEET.FIELDS.TYPE.DEFINITION', color: '#999999' },
  { key: NODES_TYPE_OPERATION, label: 'NODE.DATASHEET.FIELDS.TYPE.OPERATION', color: '#999999' },
  { key: NODES_TYPE_DATATARGET, label: 'NODE.DATASHEET.FIELDS.TYPE.DATATARGET', color: '#999999' },
  { key: NODES_TYPE_TECHFIELD, label: 'NODE.DATASHEET.FIELDS.TYPE.TECHFIELD', color: '#C2B989' },
  { key: NODES_TYPE_TECHAREA, label: 'NODE.DATASHEET.FIELDS.TYPE.TECHAREA', color: '#999999' },
  { key: NODES_TYPE_CCM, label: 'NODE.DATASHEET.FIELDS.TYPE.CCM', color: '#999999' },
  { key: NODES_TYPE_SCM, label: 'NODE.DATASHEET.FIELDS.TYPE.SCM', color: '#999999' },
  { key: NODES_TYPE_UCIM, label: 'NODE.DATASHEET.FIELDS.TYPE.UCIM', color: '#999999' },
  { key: NODES_TYPE_CWM, label: 'NODE.DATASHEET.FIELDS.TYPE.CWM', color: '#999999' },
  { key: NODES_TYPE_MCM, label: 'NODE.DATASHEET.FIELDS.TYPE.MCM', color: '#999999' },
  { key: NODES_TYPE_IPNAVIGATOR, label: 'NODE.DATASHEET.FIELDS.TYPE.IPNAVIGATOR', color: '#999999' },
  { key: NODES_TYPE_IF, label: 'NODE.DATASHEET.FIELDS.TYPE.IF', color: '#999999' },
  { key: NODES_TYPE_THEN, label: 'NODE.DATASHEET.FIELDS.TYPE.THEN', color: '#999999' },
  { key: NODES_TYPE_ELSE, label: 'NODE.DATASHEET.FIELDS.TYPE.ELSE', color: '#999999' },
  { key: NODES_TYPE_INSTANCE, label: 'NODE.DATASHEET.FIELDS.TYPE.INSTANCE', color: '#999999' },
  { key: NODES_TYPE_BUSINESSAREA, label: 'NODE.DATASHEET.FIELDS.TYPE.BUSINESSAREA', color: '#999999' },
  { key: NODES_TYPE_MODEL, label: 'NODE.DATASHEET.FIELDS.TYPE.MODEL', color: '#999999' },
  { key: NODES_TYPE_WORKFLOW, label: 'NODE.DATASHEET.FIELDS.TYPE.WORKFLOW', color: '#999999' },
  { key: NODES_TYPE_FORM, label: 'NODE.DATASHEET.FIELDS.TYPE.FORM', color: '#999999' },
  { key: NODES_TYPE_FORM_TAB, label: 'NODE.DATASHEET.FIELDS.TYPE.FORMTAB', color: '#999999' },
  { key: NODES_TYPE_FORM_OPTION, label: 'NODE.DATASHEET.FIELDS.TYPE.FORMOPTION', color: '#999999' },
  { key: NODES_TYPE_WORKFLOW_QUESTIONNAIRE, label: 'NODE.DATASHEET.FIELDS.TYPE.WORKFLOWQUESTIONNAIRE', color: '#999999' },
  { key: NODES_TYPE_WORKFLOW_QUESTIONNAIRE_TAB, label: 'NODE.DATASHEET.FIELDS.TYPE.WORKFLOWQUESTIONNAIRETAB', color: '#999999' },
  { key: NODES_TYPE_WORKFLOW_LINK, label: 'NODE.DATASHEET.FIELDS.TYPE.WORKFLOWLINK', color: '#999999' },
  { key: NODES_TYPE_NODE, label: 'NODE.DATASHEET.FIELDS.TYPE.NODE', color: '#999999' },
  { key: NODES_TYPE_CATEGORY, label: 'NODE.DATASHEET.FIELDS.TYPE.CATEGORY', color: '#81bad6' },
  { key: NODES_TYPE_CATEGORYGROUP, label: 'NODE.DATASHEET.FIELDS.TYPE.CATEGORYGROUP', color: '#999999' },
  { key: NODES_TYPE_INDUSTRY, label: 'NODE.DATASHEET.FIELDS.TYPE.INDUSTRY', color: '#999999' },
  { key: NODES_TYPE_MACROTREND, label: 'NODE.DATASHEET.FIELDS.TYPE.MACROTREND', color: '#999999' },
  { key: NODES_TYPE_MEGATREND, label: 'NODE.DATASHEET.FIELDS.TYPE.MEGATREND', color: '#999999' },
  { key: NODES_TYPE_MICROTREND, label: 'NODE.DATASHEET.FIELDS.TYPE.MICROTREND', color: '#999999' },
  { key: NODES_TYPE_IDEA, label: 'NODE.DATASHEET.FIELDS.TYPE.IDEA', color: '#c73264' },
  { key: NODES_TYPE_PRODUCT, label: 'NODE.DATASHEET.FIELDS.TYPE.PRODUCT', color: '#6FBFD8' },
  { key: NODES_TYPE_MILESTONE, label: 'NODE.DATASHEET.FIELDS.TYPE.MILESTONE', color: '#a5dccc' },
  { key: NODES_TYPE_MODULECONFIGURATION, label: 'NODE.DATASHEET.FIELDS.TYPE.MODULECONFIGURATION', color: '#999999' },
  { key: NODES_TYPE_WIDGET, label: 'NODE.DATASHEET.FIELDS.TYPE.WIDGET', color: '#999999' },
  { key: NODES_TYPE_SMARTACTION, label: 'NODE.DATASHEET.FIELDS.TYPE.SMARTACTION', color: '#999999' },
  { key: NODES_TYPE_DATASHEET, label: 'NODE.DATASHEET.FIELDS.TYPE.DATASHEET', color: '#999999' },
  { key: NODES_TYPE_DELETE, label: 'NODE.DATASHEET.FIELDS.TYPE.DELETE', color: '#999999' },
  { key: NODES_TYPE_GANTT, label: 'NODE.DATASHEET.FIELDS.TYPE.GANTT', color: '#999999' },
  { key: NODES_TYPE_TABLEGROUP, label: 'NODE.DATASHEET.FIELDS.TYPE.TABLEGROUP', color: '#999999' },
  { key: NODES_TYPE_ALLOWED, label: 'NODE.DATASHEET.FIELDS.TYPE.ALLOWED', color: '#999999' },
  { key: NODES_TYPE_ACTIONBAR, label: 'NODE.DATASHEET.FIELDS.TYPE.ACTIONBAR', color: '#999999' },
  { key: NODES_TYPE_MUSTHAVE, label: 'NODE.DATASHEET.FIELDS.TYPE.MUSTHAVE', color: '#999999' },
  { key: NODES_TYPE_ADD, label: 'NODE.DATASHEET.FIELDS.TYPE.ADD', color: '#999999' },
  { key: NODES_TYPE_CONNECT, label: 'NODE.DATASHEET.FIELDS.TYPE.CONNECT', color: '#4f84b2' },
  { key: NODES_TYPE_DISCONNECT, label: 'NODE.DATASHEET.FIELDS.TYPE.DISCONNECT', color: '#999999' },
  { key: NODES_TYPE_SPLASHSCREEN, label: 'NODE.DATASHEET.FIELDS.TYPE.SPLASHSCREEN', color: '#999999' },
  { key: NODES_TYPE_WELCOME_POWER, label: 'Welcome Power User', color: '#999999' },
  { key: NODES_TYPE_WELCOME_REGULAR, label: 'Welcome regular user', color: '#999999' },
  { key: NODES_TYPE_WIDGET_ROW, label: 'NODE.DATASHEET.FIELDS.TYPE.WIDGETROW', color: '#999999' },
  { key: NODES_TYPE_FIELDS, label: 'NODE.DATASHEET.FIELDS.TYPE.FIELDS', color: '#999999' },
  { key: NODES_TYPE_FIELD, label: 'NODE.DATASHEET.FIELDS.TYPE.FIELD', color: '#999999' },
  { key: NODES_TYPE_METHODOLOGY, label: 'NODE.DATASHEET.FIELDS.TYPE.METHODOLOGY', color: '#999999' },
  { key: NODES_TYPE_ACTION, label: 'NODE.DATASHEET.FIELDS.TYPE.ACTION', color: '#999999' },
  { key: NODES_TYPE_SETUPACTION, label: 'NODE.DATASHEET.FIELDS.TYPE.SETUPACTION', color: '#999999' },
  { key: NODES_TYPE_OBJECTIVE, label: 'NODE.DATASHEET.FIELDS.TYPE.OBJECTIVE', color: '#0C485A' },
  { key: NODES_TYPE_CHALLENGE, label: 'NODE.DATASHEET.FIELDS.TYPE.CHALLENGE', color: '#F0BB1B' },
  { key: NODES_TYPE_DELIVERABLE, label: 'NODE.DATASHEET.FIELDS.TYPE.DELIVERABLE', color: '#1E91AE' },
  { key: NODES_TYPE_STRATEGYMAP, label: 'NODE.DATASHEET.FIELDS.TYPE.STRATEGYMAP', color: '#999999' },
  { key: NODES_TYPE_PROJECTPORTFOLIO, label: 'NODE.DATASHEET.FIELDS.TYPE.PROJECTPORTFOLIO', color: '#999999' },
  { key: NODES_TYPE_ACTIONGROUP, label: 'NODE.DATASHEET.FIELDS.TYPE.ACTIONGROUP', color: '#999999' },
  { key: NODES_TYPE_HOMEACTION, label: 'NODE.DATASHEET.FIELDS.TYPE.HOMEACTION', color: '#999999' },
  { key: NODES_TYPE_NODETYPEGROUP, label: 'NODE.DATASHEET.FIELDS.TYPE.NODETYPEGROUP', color: '#999999' },
  { key: NODES_TYPE_NEXT, label: 'NODE.DATASHEET.FIELDS.TYPE.NEXT', color: '#999999' },
  { key: NODES_TYPE_SORTING, label: 'NODE.DATASHEET.FIELDS.TYPE.SORTING', color: '#999999' },
  { key: NODES_TYPE_FACTSHEET, label: 'NODE.DATASHEET.FIELDS.TYPE.FACTSHEET', color: '#999999' },
  { key: NODES_TYPE_HUMANRESOURCE, label: 'NODE.DATASHEET.FIELDS.TYPE.HUMANRESOURCE', color: '#1e293b' },
  { key: NODES_TYPE_GROUP, label: 'NODE.DATASHEET.FIELDS.TYPE.GROUP', color: '#C1DDDA' },
  { key: NODES_TYPE_CAPABILITY, label: 'NODE.DATASHEET.FIELDS.TYPE.CAPABILITY', color: '#0079A2' },
  { key: NODES_TYPE_FILTERS, label: 'NODE.DATASHEET.FIELDS.TYPE.FILTERS', color: '#999999' },
  { key: NODES_TYPE_ORGANISATIONALUNIT, label: 'NODE.DATASHEET.FIELDS.TYPE.ORGANISATIONALUNIT', color: '#999999' },
  { key: NODES_TYPE_TEAM, label: 'NODE.DATASHEET.FIELDS.TYPE.TEAM', color: '#61A4BA' },
  { key: NODES_TYPE_AI, label: 'NODE.DATASHEET.FIELDS.TYPE.AI', color: '#999999' },
  { key: NODES_TYPE_TEXTBLOCK, label: 'NODE.DATASHEET.FIELDS.TYPE.TEXTBLOCK', color: '#999999' },
  { key: NODES_TYPE_ANALYSE_STRUCTURE, label: 'NODE.DATASHEET.FIELDS.TYPE.ANALYSESTRUCTURE', color: '#999999' },
  { key: NODES_TYPE_ENTITY, label: 'NODE.DATASHEET.FIELDS.TYPE.ENTITY', color: '#999999' },
  { key: NODES_TYPE_ANALYSE, label: 'NODE.DATASHEET.FIELDS.TYPE.ANALYSE', color: '#A52250' },
  { key: NODES_TYPE_ANALYSEFOLDER, label: 'NODE.DATASHEET.FIELDS.TYPE.ANALYSEFOLDER', color: '#999999' },
  { key: NODES_TYPE_KEYWORD, label: 'NODE.DATASHEET.FIELDS.TYPE.KEYWORD', color: '#999999' },
  { key: NODES_TYPE_COMMENT, label: 'NODE.DATASHEET.FIELDS.TYPE.COMMENT', color: '#999999' },
  { key: NODES_TYPE_REGION, label: 'NODE.DATASHEET.FIELDS.TYPE.REGION', color: '#12140D' },
  { key: NODES_TYPE_RELATIONSHIP, label: 'NODE.DATASHEET.FIELDS.TYPE.RELATIONSHIP', color: '#999999' },
  { key: NODES_TYPE_EDIT, label: 'NODE.DATASHEET.FIELDS.TYPE.EDIT', color: '#999999' },
  { key: NODES_TYPE_PARENT, label: 'NODE.DATASHEET.FIELDS.TYPE.PARENT', color: '#999999' },
  { key: NODES_TYPE_CHILD, label: 'NODE.DATASHEET.FIELDS.TYPE.CHILD', color: '#999999' },
  { key: NODES_TYPE_GLOBALFILTER, label: 'NODE.DATASHEET.FIELDS.TYPE.GLOBALFILTER', color: '#999999' },
  { key: NODES_TYPE_PLANTS, label: 'NODE.DATASHEET.FIELDS.TYPE.PLANTS', color: '#003559' },
  { key: NODES_TYPE_FUNCTIONS, label: 'NODE.DATASHEET.FIELDS.TYPE.FUNCTIONS', color: '#B7094C' },
  { key: NODES_TYPE_COLUMN, label: 'NODE.DATASHEET.FIELDS.TYPE.COLUMN', color: '#999999' },
  { key: NODES_TYPE_TASK, label: 'NODE.DATASHEET.FIELDS.TYPE.TASK', color: '#999999' },
  { key: NODES_TYPE_CAPACITY, label: 'NODE.DATASHEET.FIELDS.TYPE.CAPACITY', color: '#999999' },
  { key: NODES_TYPE_DRAG, label: 'NODE.DATASHEET.FIELDS.TYPE.DRAG', color: '#999999' },
  { key: NODES_TYPE_DROP, label: 'NODE.DATASHEET.FIELDS.TYPE.DROP', color: '#999999' },
  { key: NODES_TYPE_COLORLABELPROVIDER, label: 'NODE.DATASHEET.FIELDS.TYPE.COLORLABELPROVIDER', color: '#999999' },
  { key: NODES_TYPE_COUNTERMEASURE, label: 'NODE.DATASHEET.FIELDS.TYPE.COUNTERMEASURE', color: '#999999' },
  { key: NODES_TYPE_SUM, label: 'NODE.DATASHEET.FIELDS.TYPE.SUM', color: '#999999' },
  { key: NODES_TYPE_GLOBALSUM, label: 'NODE.DATASHEET.FIELDS.TYPE.GLOBALSUM', color: '#999999' },
  { key: NODES_TYPE_IGNORE, label: 'NODE.DATASHEET.FIELDS.TYPE.IGNORE', color: '#999999' },
  { key: NODES_TYPE_UPDATE, label: 'NODE.DATASHEET.FIELDS.TYPE.UPDATE', color: '#999999' },
  { key: NODES_TYPE_INFRACTION, label: 'NODE.DATASHEET.FIELDS.TYPE.INFRACTION', color: '#999999' },
  { key: NODES_TYPE_GAP, label: 'NODE.DATASHEET.FIELDS.TYPE.GAP', color: '#c9182c' },
  { key: NODES_TYPE_SETTINGS, label: 'NODE.DATASHEET.FIELDS.TYPE.SETTINGS', color: '#999999' },
  { key: NODES_TYPE_SEARCH, label: 'NODE.DATASHEET.FIELDS.TYPE.SEARCH', color: '#ed625e' },
  { key: NODES_TYPE_SELECT, label: 'NODE.DATASHEET.FIELDS.TYPE.SELECT', color: '#999999' },
  { key: NODES_TYPE_TOGGLE, label: 'NODE.DATASHEET.FIELDS.TYPE.TOGGLE', color: '#999999' },
  { key: NODES_TYPE_WIDGET_HEADER, label: 'NODE.DATASHEET.FIELDS.TYPE.WIDGET_HEADER', color: '#999999' },
  { key: NODES_TYPE_WIDGET_FOOTER, label: 'NODE.DATASHEET.FIELDS.TYPE.WIDGET_FOOTER', color: '#999999' },
  { key: NODES_TYPE_PLACE_CHILDREN_ASIDE, label: 'NODE.DATASHEET.FIELDS.TYPE.PLACE_CHILDREN_ASIDE', color: '#999999' },
  { key: NODES_TYPE_CURVED_LINK, label: 'NODE.DATASHEET.FIELDS.TYPE.CURVED_LINK', color: '#999999' },
  { key: NODES_TYPE_LEGEND, label: 'NODE.DATASHEET.FIELDS.TYPE.LEGEND', color: '#999999' },
  { key: NODES_TYPE_FOOTER_TABS, label: 'NODE.DATASHEET.FIELDS.TYPE.FOOTER_TABS', color: '#999999' },
  { key: NODES_TYPE_FOCUS, label: 'NODE.DATASHEET.FIELDS.TYPE.FOCUS', color: '#999999' },
  { key: NODES_TYPE_STEP, label: 'NODE.DATASHEET.FIELDS.TYPE.STEP', color: '#999999' },
  { key: NODES_TYPE_ADD_ELEMENT, label: 'NODE.DATASHEET.FIELDS.TYPE.ADD_ELEMENT', color: '#999999' },
  { key: NODES_TYPE_ADD_CHILD, label: 'NODE.DATASHEET.FIELDS.TYPE.ADD_CHILD', color: '#999999' },
  { key: NODES_TYPE_LINK, label: 'NODE.DATASHEET.FIELDS.TYPE.LINK', color: '#999999' },
  { key: NODES_TYPE_RECEIVER, label: 'NODE.DATASHEET.FIELDS.TYPE.RECEIVER', color: '#999999' },
  { key: NODES_TYPE_STRUCTURE, label: 'NODE.DATASHEET.FIELDS.TYPE.STRUCTURE', color: '#999999' },
  { key: NODES_TYPE_ORGLEVEL_1, label: 'NODE.DATASHEET.FIELDS.TYPE.ORGLEVEL-1', color: '#85A1AD' },
  { key: NODES_TYPE_ORGLEVEL_2, label: 'NODE.DATASHEET.FIELDS.TYPE.ORGLEVEL-2', color: '#999999' },
  { key: NODES_TYPE_ORGLEVEL_3, label: 'NODE.DATASHEET.FIELDS.TYPE.ORGLEVEL-3', color: '#999999' },
  { key: NODES_TYPE_WBS_PROGRAM, label: 'NODE.DATASHEET.FIELDS.TYPE.WBS-PROGRAM', color: '#4E6D90' },
  { key: NODES_TYPE_WBS_WORKPACKAGE, label: 'NODE.DATASHEET.FIELDS.TYPE.WBS-WORKPACKAGE', color: '#9EB39D' },
  { key: NODES_TYPE_WBS_ACTIVITY, label: 'NODE.DATASHEET.FIELDS.TYPE.WBS-ACTIVITY', color: '#C3DCA7' },
  { key: NODES_TYPE_WBS_SUBPROJECTS, label: 'NODE.DATASHEET.FIELDS.TYPE.WBS-SUBPROJECTS', color: '#7BCEB5' },
  { key: NODES_TYPE_SKILLS, label: 'NODE.DATASHEET.FIELDS.TYPE.SKILLS', color: '#999999' },
  { key: NODES_TYPE_DEMAND, label: 'NODE.DATASHEET.FIELDS.TYPE.DEMAND', color: '#999999' },
  { key: NODES_TYPE_ASSET_MACHINE, label: 'NODE.DATASHEET.FIELDS.TYPE.ASSET-MACHINE', color: '#999999' },
  { key: NODES_TYPE_ASSET_MODULE, label: 'NODE.DATASHEET.FIELDS.TYPE.ASSET-MODULE', color: '#999999' },
  { key: NODES_TYPE_PRODUCTGROUP, label: 'NODE.DATASHEET.FIELDS.TYPE.PRODUCTGROUP', color: '#2E1780' },
  { key: NODES_TYPE_ASSET_GROUP, label: 'NODE.DATASHEET.FIELDS.TYPE.ASSET-GROUP', color: '#999999' },
  { key: NODES_TYPE_BADWORD, label: 'NODE.DATASHEET.FIELDS.TYPE.BADWORD', color: '#999999' },
  { key: NODES_TYPE_MEMBER, label: 'NODE.DATASHEET.FIELDS.TYPE.MEMBER', color: '#999999' },
  { key: NODES_TYPE_DUPLICATE, label: 'NODE.DATASHEET.FIELDS.TYPE.DUPLICATE', color: '#999999' },
  { key: NODES_TYPE_REVERSE, label: 'NODE.DATASHEET.FIELDS.TYPE.REVERSE', color: '#999999' },
  { key: NODES_TYPE_ROUNDED, label: 'NODE.DATASHEET.FIELDS.TYPE.ROUNDED', color: '#999999' },
  { key: NODES_TYPE_SUGGESTIONS, label: 'NODE.DATASHEET.FIELDS.TYPE.SUGGESTIONS', color: '#999999' },
  { key: NODES_TYPE_FULL, label: 'NODE.DATASHEET.FIELDS.TYPE.FULL', color: '#999999' },
  { key: NODES_TYPE_CENTER, label: 'NODE.DATASHEET.FIELDS.TYPE.CENTER', color: '#999999' },
  { key: NODES_TYPE_HIERARCHY, label: 'NODE.DATASHEET.FIELDS.TYPE.HIERARCHY', color: '#999999' },
  { key: NODES_TYPE_REMAINING, label: 'NODE.DATASHEET.FIELDS.TYPE.REMAINING', color: '#999999' },
  { key: NODES_TYPE_ONLY_ONE_STACK, label: 'Only one stack', color: '#999999' },
  { key: NODES_TYPE_VERTICAL, label: 'NODE.DATASHEET.FIELDS.TYPE.VERTICAL', color: '#999999' },
  { key: NODES_TYPE_HORIZONTAL, label: 'NODE.DATASHEET.FIELDS.TYPE.HORIZONTAL', color: '#999999' },
  { key: NODES_TYPE_AXIS, label: 'NODE.DATASHEET.FIELDS.TYPE.AXIS', color: '#999999' },
  { key: NODES_TYPE_TABLEGROUPMONTHS, label: 'NODE.DATASHEET.FIELDS.TYPE.TABLEGROUPMONTHS', color: '#999999' },
  { key: NODES_TYPE_DEFAULTVALUES, label: 'NODE.DATASHEET.FIELDS.TYPE.DEFAULTVALUES', color: '#999999' },
  { key: NODES_TYPE_STRAIGHT_LINKS, label: 'NODE.DATASHEET.FIELDS.TYPE.STRAIGHT-LINKS', color: '#999999' },
  { key: NODES_TYPE_DIRECT_CHAIN, label: 'NODE.DATASHEET.FIELDS.TYPE.DIRECT-CHAIN', color: '#999999' },
  { key: NODES_TYPE_FORM_ROW, label: 'NODE.DATASHEET.FIELDS.TYPE.FORM-ROW', color: '#999999' },
  { key: NODES_TYPE_FORM_COLUMN, label: 'NODE.DATASHEET.FIELDS.TYPE.FORM-COLUMN', color: '#999999' },
  { key: NODES_TYPE_REMINDER, label: 'NODE.DATASHEET.FIELDS.TYPE.REMINDER', color: '#999999' },
  { key: NODES_TYPE_ASSET_POSITION, label: 'NODE.DATASHEET.FIELDS.TYPE.ASSET-POSITION', color: '#999999' },
  { key: NODES_TYPE_MANUFACTURING_SLOT, label: 'NODE.DATASHEET.FIELDS.TYPE.MANUFACTURING-SLOT', color: '#999999' },
  { key: NODES_TYPE_BY, label: 'NODE.DATASHEET.FIELDS.TYPE.BY', color: '#999999' },
  { key: NODES_TYPE_VALUE, label: 'NODE.DATASHEET.FIELDS.TYPE.VALUE', color: '#999999' },
  { key: NODES_TYPE_PAGINATION, label: 'NODE.DATASHEET.FIELDS.TYPE.PAGINATION', color: '#999999' },
  { key: NODES_TYPE_SLOT, label: 'NODE.DATASHEET.FIELDS.TYPE.SLOT', color: '#006DAA' },
  { key: NODES_TYPE_LINE, label: 'NODE.DATASHEET.FIELDS.TYPE.LINE', color: '#043D74' },
  { key: NODES_TYPE_MACHINE, label: 'NODE.DATASHEET.FIELDS.TYPE.MACHINE', color: '#33496D' },
  { key: NODES_TYPE_MODULE, label: 'NODE.DATASHEET.FIELDS.TYPE.MODULE', color: '#607899' },
  { key: NODES_TYPE_TRANSFER, label: 'NODE.DATASHEET.FIELDS.TYPE.TRANSFER', color: '#999999' },
  { key: NODES_TYPE_MUTATION, label: 'NODE.DATASHEET.FIELDS.TYPE.MUTATION', color: '#999999' },
  { key: NODES_TYPE_STANDARD_LINE, label: 'NODE.DATASHEET.FIELDS.TYPE.STANDARD-LINE', color: '#999999' },
  { key: NODES_TYPE_ORGLEVEL_6, label: 'NODE.DATASHEET.FIELDS.TYPE.ORGLEVEL-6', color: '#0D5E8A' },
  { key: NODES_TYPE_ORGLEVEL_7, label: 'NODE.DATASHEET.FIELDS.TYPE.ORGLEVEL-7', color: '#999999' },
  { key: NODES_TYPE_ORGLEVEL_5, label: 'NODE.DATASHEET.FIELDS.TYPE.ORGLEVEL-5', color: '#999999' },
  { key: NODES_TYPE_MY, label: 'NODE.DATASHEET.FIELDS.TYPE.MY', color: '#999999' },
  { key: NODES_TYPE_ROLLOUT, label: 'NODE.DATASHEET.FIELDS.TYPE.ROLLOUT', color: '#FFFACD' },
  { key: NODES_TYPE_PROCESS, label: 'NODE.DATASHEET.FIELDS.TYPE.PROCESS', color: '#999999' },
  { key: NODES_TYPE_PROJECT_CATEGORY, label: 'NODE.DATASHEET.FIELDS.TYPE.PROJECT-CATEGORY', color: '#999999' },
  { key: NODES_TYPE_PREFILTER, label: 'NODE.DATASHEET.FIELDS.TYPE.PREFILTER', color: '#999999' },
  { key: NODES_TYPE_VERSION, label: 'NODE.DATASHEET.FIELDS.TYPE.VERSION', color: '#999999' },
  { key: NODES_TYPE_POSITION, label: 'NODE.DATASHEET.FIELDS.TYPE.POSITION', color: '#999999' },
  { key: NODES_TYPE_STANDARD_CONFIGURATION, label: 'NODE.DATASHEET.FIELDS.TYPE.STANDARD-CONFIGURATION', color: '#999999' },
  { key: NODES_TYPE_SUPPLIER, label: 'NODE.DATASHEET.FIELDS.TYPE.SUPPLIER', color: '#999999' },
  { key: NODES_TYPE_OFFLINE, label: 'NODE.DATASHEET.FIELDS.TYPE.OFFLINE', color: '#999999' },
  { key: NODES_TYPE_EVENT, label: 'NODE.DATASHEET.FIELDS.TYPE.EVENT', color: '#999999' },
  { key: NODES_TYPE_MACHINE_TYPE, label: 'NODE.DATASHEET.FIELDS.TYPE.MACHINE-TYPE', color: '#5A20D8' },
  { key: NODES_TYPE_LC_STATE, label: 'NODE.DATASHEET.FIELDS.TYPE.LCSTATE', color: '#999999' },
  { key: NODES_TYPE_NODETYPEGROUPS, label: 'NODE.DATASHEET.FIELDS.TYPE.NODETYPEGROUPS', color: '#999999' },
  { key: NODES_TYPE_ALTERNATIVE, label: 'NODE.DATASHEET.FIELDS.TYPE.ALTERNATIVE', color: '#999999' },
  { key: NODES_TYPE_EVENT_CATEGORY, label: 'NODE.DATASHEET.FIELDS.TYPE.EVENT-CATEGORY', color: '#999999' },
  { key: NODES_TYPE_LABEL, label: 'NODE.DATASHEET.FIELDS.TYPE.LABEL', color: '#999999' },
  { key: NODES_TYPE_SHARE, label: 'NODE.DATASHEET.FIELDS.TYPE.SHARE', color: '#999999' },
  { key: NODES_TYPE_TREE, label: 'NODE.DATASHEET.FIELDS.TYPE.TREE', color: '#999999' },
  { key: NODES_TYPE_NOT, label: 'NODE.DATASHEET.FIELDS.TYPE.NOT', color: '#999999' },
  { key: NODES_TYPE_NOTIFICATIONS, label: 'NODE.DATASHEET.FIELDS.TYPE.NOTIFICATIONS', color: '#999999' },
  { key: NODES_TYPE_PRIVATE, label: 'NODE.DATASHEET.FIELDS.TYPE.PRIVATE', color: '#999999' },
  { key: NODES_TYPE_TRAVERSER, label: 'NODE.DATASHEET.FIELDS.TYPE.TRAVERSER', color: '#999999' },
  { key: NODES_TYPE_NFM, label: 'NODE.DATASHEET.FIELDS.TYPE.NFM', color: '#999999' },
  { key: NODES_TYPE_PRODUCT_AREA, label: 'NODE.DATASHEET.FIELDS.TYPE.PRODUCT-AREA', color: '#999999' },
  { key: NODES_TYPE_SEQUENCE, label: 'NODE.DATASHEET.FIELDS.TYPE.SEQUENCE', color: '#999999' },
  { key: NODES_TYPE_ENTRY, label: 'NODE.DATASHEET.FIELDS.TYPE.ENTRY', color: '#999999' },
  { key: NODES_TYPE_ANSWER, label: 'NODE.DATASHEET.FIELDS.TYPE.ANSWER', color: '#999999' },
  { key: NODES_TYPE_TAG, label: 'NODE.DATASHEET.FIELDS.TYPE.TAG', color: '#999999' },
  { key: NODES_TYPE_DOCUMENT, label: 'NODE.DATASHEET.FIELDS.TYPE.DOCUMENT', color: '#999999' },
  { key: NODES_TYPE_DATASTORAGE, label: 'NODE.DATASHEET.FIELDS.TYPE.DATA-STORAGE', color: '#999999' },
  { key: NODES_TYPE_PROMPT, label: 'NODE.DATASHEET.FIELDS.TYPE.PROMPT', color: '#999999' },
  { key: NODES_TYPE_EXTERNAL_INPUT, label: 'NODE.DATASHEET.FIELDS.TYPE.EXTERNAL-INPUT', color: '#999999' },
  { key: NODES_TYPE_INPUT, label: 'NODE.DATASHEET.FIELDS.TYPE.INPUT', color: '#999999' },
  { key: NODES_TYPE_VIDEO, label: 'NODE.DATASHEET.FIELDS.TYPE.VIDEO', color: '#999999' },
  { key: NODES_TYPE_COEFFICIENT, label: 'NODE.DATASHEET.FIELDS.TYPE.COEFFICIENT', color: '#d68181' },
  { key: NODES_TYPE_CALCULATION, label: 'NODE.DATASHEET.FIELDS.TYPE.CALCULATION', color: '#999999' },
  { key: NODES_TYPE_CONVERSION, label: 'NODE.DATASHEET.FIELDS.TYPE.CONVERSION', color: '#999999' },
  { key: NODES_TYPE_PERCENTAGE, label: 'NODE.DATASHEET.FIELDS.TYPE.PERCENTAGE', color: '#999999' },
  { key: NODES_TYPE_CONDITION, label: 'NODE.DATASHEET.FIELDS.TYPE.CONDITION', color: '#999999' },
  { key: NODES_TYPE_DECIMALS, label: 'NODE.DATASHEET.FIELDS.TYPE.DECIMALS', color: '#999999' },
  { key: NODES_TYPE_THOUSANDS, label: 'NODE.DATASHEET.FIELDS.TYPE.THOUSANDS', color: '#999999' },
  { key: NODES_TYPE_NO_DECIMAL, label: 'NODE.DATASHEET.FIELDS.TYPE.NO_DECIMAL', color: '#999999' },
  { key: NODES_TYPE_DATE, label: 'NODE.DATASHEET.FIELDS.TYPE.DATE', color: '#999999' },
  { key: NODES_TYPE_EXPORT, label: 'NODE.DATASHEET.FIELDS.TYPE.EXPORT', color: '#999999' },
  { key: NODES_TYPE_COLOR, label: 'NODE.DATASHEET.FIELDS.TYPE.COLOR', color: '#999999' },
  { key: NODES_TYPE_STYLE, label: 'NODE.DATASHEET.FIELDS.TYPE.STYLE', color: '#999999' },
  { key: NODES_TYPE_APP, label: 'NODE.DATASHEET.FIELDS.TYPE.APP', color: '#999999' },
  { key: NODES_TYPE_CHAT, label: 'NODE.DATASHEET.FIELDS.TYPE.CHAT', color: '#999999' },
  { key: NODES_TYPE_UPDATE_PERIPHERY, label: 'NODE.DATASHEET.FIELDS.TYPE.UPDATE_PERIPHERY', color: '#999999' },
  { key: NODES_TYPE_COLLAPSE, label: 'NODE.DATASHEET.FIELDS.TYPE.COLLAPSE', color: '#999999' },
  { key: NODES_TYPE_AGENT, label: 'NODE.DATASHEET.FIELDS.TYPE.AGENT', color: '#999999' },
  { key: NODES_TYPE_PLACEHOLDER, label: 'NODE.DATASHEET.FIELDS.TYPE.PLACEHOLDER', color: '#999999' },
  { key: NODES_TYPE_SUBJECT, label: 'NODE.DATASHEET.FIELDS.TYPE.SUBJECT', color: '#999999' },
  { key: NODES_TYPE_TEMPLATE, label: 'NODE.DATASHEET.FIELDS.TYPE.TEMPLATE', color: '#999999' },
  { key: NODES_TYPE_START, label: 'NODE.DATASHEET.FIELDS.TYPE.START', color: '#999999' },
  { key: NODES_TYPE_END, label: 'NODE.DATASHEET.FIELDS.TYPE.END', color: '#999999' },
  { key: NODES_TYPE_KNOWLEDGE_GRAPH, label: 'NODE.DATASHEET.FIELDS.TYPE.KNOWLEDGE_GRAPH', color: '#999999' },
  { key: NODES_TYPE_SEARCH_QUERY, label: 'NODE.DATASHEET.FIELDS.TYPE.SEARCH_QUERY', color: '#999999' },
  { key: NODES_TYPE_PAGE, label: 'NODE.DATASHEET.FIELDS.TYPE.PAGE', color: '#999999' },
];

export interface TreeRelationship {
  /* Ids */
  id: string;
  parentId?: string;
  childId?: string;
  originalParentId?: string;
  originalChildId?: string;
  modelId?: string;
  internalType?: string;
  updatedAt?: number;
  version_id?: number;
  /* Data */
  rel?: string;
  phantom?: boolean;
  virtual?: boolean;
  ai?: boolean;
  weight?: number;
  condition?: number;
  category?: number;
  type?: number;
  filtered?: boolean;
  connected?: boolean;
  startDate?: string;
  endDate?: string;
  /* Additional parameter for in app usage */
  source?: TreeNode | string;
  target?: TreeNode | string;
  originalSource?: TreeNode | string;
  originalTarget?: TreeNode | string;
  /* Only app usage */
  isTRT?: boolean;
  highlight?: string;
  x1?: number;
  y1?: number;
  x2?: number;
  y2?: number;
  originalWeight?: number;
}

export interface ChatGPTConversation {
  id: string;
  conversation: ChatGPTInput[];
}
export interface ChatGPTInput {
  role: string;
  content: string;
}
export interface ExportExcel {
  sheet: { data: ExportExcelData[] };
}

export interface ExportExcelData {
  headers: ExportExcelCell[][];
  rows: ExportExcelCell[][];
  styling: string;
}

export interface ExportExcelCell {
  value: string;
  type: string;
  colspan: number;
  rowspan: number;
  bgcolor?: string;
  color?: string;
}

export interface CoreOptions {
  filters?: CoreFilter[];
  structureGlobalFilter?: boolean;
  dataGlobalFilter?: boolean;
  ignoreGlobalFilter?: boolean;
  ignoreBusinessArea?: boolean;
  ignoreMCM?: boolean;
  onlyMCM?: boolean;
  stopAt?: number[];
  makeImmutable?: boolean;
  ignoreCache?: boolean;
  widgetId?: string;
}

export interface CoreGlobalFilter {
  filterType: string;
  id: string;
  widgetId?: string;
  key: string;
  type: string;
  value: any;
  initialValue: any;
  order: number;
  defaultValue: string;
  subtract?: boolean;
  directChain?: boolean;
  directChildren?: boolean;
  directParents?: boolean;
  traverser?: string;
  useTRT?: boolean;
  traverserLaneId?: string;
  entryNodeIDs?: string[];
  ignoreNodeType?: any;
}

export interface CoreWidget extends GridsterItem {
  id: string;
  widgetId: string;
  fx: number;
  fy: number;
  fullscreen: boolean;
  hidden: string[];
  rowWeight: number;
  colWeight: number;
  businessAreaId: string;
  modelIds: string[];
  isGlobal: boolean;
  nodeFilterKey: string;
  methodology: TreeNode;
  activate: () => void;
  destroy: EventEmitter<any>;
  nodes: Observable<TreeNode[]>;
  nodesWOGlobal: Observable<TreeNode[]>;
  nodesWMcm: Observable<TreeNode[]>;
  relationships: Observable<TreeRelationship[]>;
  humanResources: Observable<CoreHumanResource[]>;
  groups: Observable<CoreGroup[]>;
  hierarchy: Observable<TreeNode[]>;
  legends: Observable<CoreLegend[]>;
  configuration: TreeNode;
  screen: DashboardScreenComponent;
  redesign: boolean;
  resize: EventEmitter<any>;
  cancel: EventEmitter<any>;
  updated: EventEmitter<any>;
  toggleSubtract: BehaviorSubject<boolean>;
  sendMessages: EventEmitter<CoreWidgetMessage[]>;
  receiveMessages: BehaviorSubject<CoreWidgetMessage[]>;
  position: string; // For later usage to position the widget with the use of a grid system
  reset: boolean;
  useHierarchy: boolean;
  external: boolean;
}

export interface CoreWidgetMessage {
  id: string;
  sender: string;
  receiver?: string;
  data: any;
  toggleWidget?: boolean;
  storeToggle?: boolean;
  direct?: boolean;
}

export interface CoreTransfer {
  instanceId?: string;
  businessAreaId?: string;
  modelId?: string;
  type?: string;
  token?: string;

  businessArea?: Businessarea | IPayload;
  models?: ModelCreate[] | IPayload[];
  activities?: TreeActivity[] | IPayload[] | Activity[] | string[];
  nodes: TreeNode[] | IPayload[] | NodeCreate[] | string[];
  relationships: TreeRelationship[] | IPayload[] | RelationshipCreate[] | string[];
  humanResources?: CoreHumanResource[] | IPayload[] | HumanResource[] | string[];
  groups?: CoreGroup[] | IPayload[] | Group[] | string[];
  nodeStructures?: TreeNode[] | IPayload[] | NodeCreate[] | string[];

  ids?: string[];
}

export interface CoreMultiTransfer {
  create: CoreTransfer;
  update: CoreTransfer;
  delete: CoreTransfer;
  waitForCreate?: boolean;
}

export interface CoreTransferResult {
  businessAreaId?: string;
  modelIds?: string[];
  nodeIds?: string[];
  relationshipIds: string[];
}

export interface CoreDrop {
  parentTreeNode: TreeNode;
  droppedTreeNode: TreeNode;
  defaultWeight: number;
}



export interface CoreConfiguration {
  widgets: CoreWidget[];
}

export interface CoreLegend {
  key: string;
  color?: string;
  label: string;
  field?: string;
  value?: string;
  selected?: boolean;
}

export interface CoreFilter {
  id?: string;
  by: string;
  value: any | any[];
  nodeType?: number;
  directChain?: TreeNode;
  range?: boolean;
  chain?: boolean;
  child?: boolean;
  not?: boolean;
  forcedNot?: boolean;
  filterValue?: any[];
  or?: string[];
  functionsNodes?: TreeNode[];
}

export interface CoreNodeType {
  key: number;
  label: string;
  color: string;
}

export interface CoreSorting {
  field: string;
  direction: number;
}

export interface CoreModule {
  id: string;
  name: string;
  description: string;
  color: string;
  background: string;
  type: string;
  icon: string;
  businessarea_id?: string;
  actions?: CoreAction[];
}

export interface CoreUser {
  id: string;
  name: string;
  email: string;
  hrId: string;
  role: string;
  image: string;
  superUser: boolean;
  humanResources: any[];
  permissions: string[];
  settings: any;
}

export interface CoreActionGroup {
  id: string;
  name: string;
  actions: CoreAction[];
}

export interface CoreAction {
  id: string;
  name: string;
  description: string;
  color: string;
  type: string;
  background: string;
  icon: string;
  className: string;
}

export interface CoreInstance {
  id: string;
  name: string;
  type: number;
}

export interface CoreLabelProvider {
  key: string;
  label: string;
}

export interface CoreModel {
  id: string;
  name: string;
  versionname: string;
  reference: string;
  crossReference: string;
  description: string;
  color: string;
  date: string;
  startDate: string;
  status: number;
  commercialStatus: number;
  updatedAt: number;
  template: string;
  settings: any;
  humanresource: number;
  targetpicture: string;
  hypothesis: string;
  abbreviation: string;
  duplicate_original_id?: number;
  sorting: number;
  onstrategyradar: number;
  onreport: boolean;
  onprogramm: number;
  parentModel: string;
  active: boolean;
  type: number;
  icon: string;
  businessareaId: number;
}

export interface CoreHumanResource {
  id: number | string;
  email?: string;
  name?: string;
  first_name?: string;
  last_name?: string;
  color?: string;
  can_login?: boolean;
  foreign_id?: number;
  updatedAt?: number;
  permissions?: any;
  notification_settings?: any;
  storypoints?: number;
  instanceId?: string;
  image?: string;
  responsibleId?: number;
  modelId?: string;
  parents?: TreeNode[];
  unfilteredParents?: TreeNode[];
  children?: TreeNode[];
  unfilteredChildren?: TreeNode[];
}

export interface CoreGroup {
  id: string;
  name?: string;
  instanceId?: string;
  permissions?: any;
  bestCase?: number;
  humanResources?: string[];
  humanResourcesGo?: number[];
}

export interface CoreSocketData {
  data: any[];
  included: any[];
}

export interface LoadingSocketData {
  current: number;
  of: number;
  message: string;
}

export interface CoreExportOptions {
  header?: string;
  pageNumber?: boolean;
  footer?: string;
  screenCSS?: boolean;
  portrait?: boolean;
  indicator?: string;
  selected?: string[];
}

export interface CoreStatusItem {
  text: string;
  color: string;
}

export interface CoreWidgetFilter {
  id: string;
  field: string;
  label: string;
  checkboxes: CoreWidgetFilterCheckbox[];
  values: any[];
}

export interface CoreWidgetFilterCheckbox {
  id: string;
  label: string;
  value: any;
  checked: boolean;
  treeNode: TreeNode;
}

export interface CoreField {
  key: string;
  controlType?: string;
  nodeId?: number;
}

export const CoreLevel = {
  0: { text: 'LEVEL.OBJECTIVE' },
  1: { text: 'LEVEL.CHALLENGE' },
  2: { text: 'LEVEL.SOLUTION' },
  3: { text: 'LEVEL.DELIVERABLE' }
};

export const CoreStatus = {
  0: <CoreStatusItem> { text: 'STATUS.PENDING', color: '#999999' },
  100: <CoreStatusItem> { text: 'STATUS.INDEFINE', color: '#000000' },
  110: <CoreStatusItem> { text: 'STATUS.INDEVELOP', color: '#1099d6' },
  120: <CoreStatusItem> { text: 'STATUS.INVERIFY', color: '#aa008f' },
  130: <CoreStatusItem> { text: 'STATUS.INACCEPT', color: '#edb922' },
  200: <CoreStatusItem> { text: 'STATUS.COMPLETED', color: '#9ac93e' }
};

export const CoreWidgets = [
    { key: '', value: '' },
    { key: 'setup', value: 'Setup' },
    { key: 'setuptargetconstruct', value: 'Setup target construct' },
    { key: 'setupprojects', value: 'Setup projects' },
    { key: 'setuphowtowork', value: 'Setup how to work' },
    { key: 'setupoverview', value: 'Setup overview' },
    { key: 'home', value: 'Home' },
    { key: 'strategicobjectives', value: 'Strategic objectives' },
    { key: 'projectsstack', value: 'Projects stack' },
    { key: 'gantt', value: 'Gantt' },
    { key: 'setbudget', value: 'Set budget' },
    { key: 'humanresources', value: 'Human resources' },
    { key: 'vertical', value: 'Vertical' },
    { key: 'horizontal', value: 'Horizontal' },
    { key: 'factsheet', value: 'Fact sheet' },
    { key: 'list', value: 'List legacy' },
    { key: 'list2', value: 'List' },
    { key: 'assignhumanresources', value: 'Assign human resources' },
    { key: 'assignteams', value: 'Assign teams' },
    { key: 'humanresourceslist', value: 'Human resources list' },
    { key: 'eisenhowermatrix', value: 'Eisenhower matrix' },
    { key: 'report', value: 'Report' },
    { key: 'notifications', value: 'Notifications' },
    { key: 'ai-analysedocument', value: 'AI Analyse document' },
    { key: 'ai-analysedocument-result', value: 'AI Analyse document result' },
    { key: 'existing-files', value: 'Existing files' },
    { key: 'file-viewer', value: 'File viewer' },
    { key: 'file-keywords', value: 'File keywords' },
    { key: 'experts-list', value: 'Experts list' },
    { key: 'send-to-markets', value: 'Send to markets' },
    { key: 'select-markets', value: 'Select markets' },
    { key: 'selected-file-viewer', value: 'Selected file viewer' },
    { key: 'review-experts', value: 'Review experts' },
    { key: 'selected-experts', value: 'Selected experts' },
    { key: 'confirm-action', value: 'Confirm action' },
    { key: 'assigned-files', value: 'Assigned files' },
    { key: 'fiscal-years', value: 'Fiscal years' },
    { key: 'operational-staffing', value: 'Operational staffing' },
    { key: 'kanban-board', value: 'Kanban board' },
    { key: 'chatGPT-kanban-board', value: 'Odin' },
    { key: 'kanban-board-legacy', value: 'Kanban board legacy' },
    { key: 'permissions2', value: 'Permissions 2' },
    { key: 'filter-byelement', value: 'Filter: By element' },
    { key: 'filter-byfields', value: 'Filter: By fields' },
    { key: 'filter-bydate', value: 'Filter: By date' },
    { key: 'filter-byconditions', value: 'Filter: By conditions' },
    { key: 'assign-element', value: 'Assign element' },
    { key: 'manage-operational-staffing', value: 'Manage operational staffing' },
    { key: 'assign-staffing', value: 'Assign staffing' },
    { key: 'gantt-projects', value: 'Projects Gantt' },
    { key: 'projects-table', value: 'Projects table' },
    { key: 'data-browser', value: 'Data browser' },
    { key: 'widget-remote', value: 'Widget remote' },
    { key: 'element-connector', value: 'Element connector' },
    { key: 'table', value: 'Table' },
    { key: 'infraction-detailed', value: 'Infraction detailed' },
    { key: 'infraction-violation', value: 'Infraction violation' },
    { key: 'infraction-members', value: 'Infraction members' },
    { key: 'infraction-reference', value: 'Infraction reference' },
    { key: 'infraction-countermeasures', value: 'Infraction countermeasures' },
    { key: 'infraction-form', value: 'Infraction form' },
    { key: 'infraction-countermeasure-form', value: 'Infraction countermeasures & form' },
    { key: 'infraction-youtubeCaption', value: 'Youtube-Caption' },
    { key: 'worldmap', value: 'World map' },
    { key: 'charts', value: 'Charts' },
    { key: 'infraction-table', value: 'Infraction table' },
    { key: 'onboarding-start', value: 'Onboarding start' },
    { key: 'onboarding-worldmap', value: 'Onboarding world map' },
    { key: 'onboarding-todos', value: 'Onboarding todos' },
    { key: 'onboarding-adddocument', value: 'Onboarding add document' },
    { key: 'onboarding-documentselect', value: 'Onboarding document select' },
    { key: 'onboarding-documentview', value: 'Onboarding document view' },
    { key: 'onboarding-documentsendtoexpert', value: 'Onboarding send document to expert' },
    { key: 'todo', value: 'To-Do list' },
    { key: 'debug', value: 'Debug (dev use only)' },
    { key: 'octimine', value: 'Octimine' },
    { key: 'guardian', value: 'Guardian' },
    { key: 'heatmap', value: 'Heatmap' },
    { key: 'overview', value: 'Overview' },
    { key: 'permissions', value: 'Permissions' },
    { key: 'form', value: 'Form' },
    { key: 'machines-transfer', value: 'Machine transfer' },
    { key: 'machines-available', value: 'Machines available' },
    { key: 'machine-transfer-overview', value: 'Machine transfer overview' },
    { key: 'lines', value: 'Lines' },
    { key: 'capabilities', value: 'Capabilities' },
    { key: 'mutator', value: 'Mutator' },
    { key: 'search', value: 'Search' },
    { key: 'machine-transfer-objects', value: 'Machine transfer objects' },
    { key: 'tree', value: 'Tree' },
    { key: 'onboarding-document-email', value: 'Onboarding document email' },
    { key: 'standard-configurations', value: 'Standard configuration edit' },
    { key: 'timeline', value: 'Timeline' },
    { key: 'comparison', value: 'Comparison' },
    { key: 'send-mail', value: 'Send mail' },
    { key: 'top-level-report', value: 'Top level report' },
    { key: 'machines-kanban', value: 'Machine transfer: Kanban' },
    { key: 'notifications-setup', value: 'Notifications setup' },
    { key: 'questionnaire', value: 'Questionnaire' },
    { key: 'sequence', value: 'Sequence helper' },
    { key: 'search-by-tags', value: 'Search by tags' },
    { key: 'video-player', value: 'Video player' },
    { key: 'iframe', value: 'IFrame' },
    { key: 'placeholder', value: 'Placeholder' },
    { key: 'database-connection', value: 'Database connection' },
    { key: 'export', value: 'Export' },
    { key: 'count', value: 'Count' },
    { key: 'wysiwyg', value: 'Wysiwyg' },
    { key: 'gpt', value: 'Private GPT' },
    { key: 'analysis', value: 'Analysis' },
    { key: 'autogen', value: 'Autogen' },
    { key: 'calendar', value: 'Calendar' },
    { key: 'ai-ingest', value: 'AI Ingest' },
    { key: 'search-config', value: 'Search configuration' },
    { key: 'page-navigation', value: 'Page navigation' },
    { key: 'page-editor', value: 'Page editor' },
    { key: 'assign-nodes', value: 'Assign nodes' },
];

export const CoreCountries = [
  { id: 'CHN', name: 'China' },
  { id: 'IND', name: 'India' },
  { id: 'USA', name: 'United States' },
  { id: 'IDN', name: 'Indonesia' },
  { id: 'BRA', name: 'Brazil' },
  { id: 'PAK', name: 'Pakistan' },
  { id: 'BGD', name: 'Bangladesh' },
  { id: 'NGA', name: 'Nigeria' },
  { id: 'RUS', name: 'Russia' },
  { id: 'JPN', name: 'Japan' },
  { id: 'MEX', name: 'Mexico' },
  { id: 'PHL', name: 'Philippines' },
  { id: 'VNM', name: 'Vietnam' },
  { id: 'ETH', name: 'Ethiopia' },
  { id: 'DEU', name: 'Germany' },
  { id: 'EGY', name: 'Egypt' },
  { id: 'TUR', name: 'Turkey' },
  { id: 'COD', name: '"Congo, Democratic Republic of the"' },
  { id: 'IRN', name: 'Iran' },
  { id: 'THA', name: 'Thailand' },
  { id: 'FRA', name: 'France' },
  { id: 'GBR', name: 'United Kingdom' },
  { id: 'ITA', name: 'Italy' },
  { id: 'MMR', name: 'Burma' },
  { id: 'ZAF', name: 'South Africa' },
  { id: 'KOR', name: '"Korea, South"' },
  { id: 'UKR', name: 'Ukraine' },
  { id: 'COL', name: 'Colombia' },
  { id: 'SDN', name: 'Sudan' },
  { id: 'TZA', name: 'Tanzania' },
  { id: 'ARG', name: 'Argentina' },
  { id: 'ESP', name: 'Spain' },
  { id: 'KEN', name: 'Kenya' },
  { id: 'POL', name: 'Poland' },
  { id: 'DZA', name: 'Algeria' },
  { id: 'CAN', name: 'Canada' },
  { id: 'UGA', name: 'Uganda' },
  { id: 'MAR', name: 'Morocco' },
  { id: 'PER', name: 'Peru' },
  { id: 'IRQ', name: 'Iraq' },
  { id: 'SAU', name: 'Saudi Arabia' },
  { id: 'AFG', name: 'Afghanistan' },
  { id: 'NPL', name: 'Nepal' },
  { id: 'UZB', name: 'Uzbekistan' },
  { id: 'VEN', name: 'Venezuela' },
  { id: 'MYS', name: 'Malaysia' },
  { id: 'GHA', name: 'Ghana' },
  { id: 'YEM', name: 'Yemen' },
  { id: 'TWN', name: 'Taiwan' },
  { id: 'PRK', name: '"Korea, North"' },
  { id: 'SYR', name: 'Syria' },
  { id: 'ROU', name: 'Romania' },
  { id: 'MOZ', name: 'Mozambique' },
  { id: 'AUS', name: 'Australia' },
  { id: 'LKA', name: 'Sri Lanka' },
  { id: 'MDG', name: 'Madagascar' },
  { id: 'CIV', name: 'Cote d Ivoire' },
  { id: 'CMR', name: 'Cameroon' },
  { id: 'NLD', name: 'Netherlands' },
  { id: 'CHL', name: 'Chile' },
  { id: 'BFA', name: 'Burkina Faso' },
  { id: 'NER', name: 'Niger' },
  { id: 'KAZ', name: 'Kazakhstan' },
  { id: 'MWI', name: 'Malawi' },
  { id: 'ECU', name: 'Ecuador' },
  { id: 'KHM', name: 'Cambodia' },
  { id: 'SEN', name: 'Senegal' },
  { id: 'MLI', name: 'Mali' },
  { id: 'GTM', name: 'Guatemala' },
  { id: 'AGO', name: 'Angola' },
  { id: 'ZMB', name: 'Zambia' },
  { id: 'ZWE', name: 'Zimbabwe' },
  { id: 'CUB', name: 'Cuba' },
  { id: 'RWA', name: 'Rwanda' },
  { id: 'GRC', name: 'Greece' },
  { id: 'PRT', name: 'Portugal' },
  { id: 'TUN', name: 'Tunisia' },
  { id: 'TCD', name: 'Chad' },
  { id: 'BEL', name: 'Belgium' },
  { id: 'GIN', name: 'Guinea' },
  { id: 'CZE', name: 'Czech Republic' },
  { id: 'SOM', name: 'Somalia' },
  { id: 'BOL', name: 'Bolivia' },
  { id: 'HUN', name: 'Hungary' },
  { id: 'BDI', name: 'Burundi' },
  { id: 'DOM', name: 'Dominican Republic' },
  { id: 'BLR', name: 'Belarus' },
  { id: 'HTI', name: 'Haiti' },
  { id: 'SWE', name: 'Sweden' },
  { id: 'BEN', name: 'Benin' },
  { id: 'AZE', name: 'Azerbaijan' },
  { id: 'AUT', name: 'Austria' },
  { id: 'HND', name: 'Honduras' },
  { id: 'CHE', name: 'Switzerland' },
  { id: 'TJK', name: 'Tajikistan' },
  { id: 'ISR', name: 'Israel' },
  { id: 'SRB', name: 'Serbia' },
  { id: 'BGR', name: 'Bulgaria' },
  { id: 'HKG', name: 'Hong Kong' },
  { id: 'LAO', name: 'Laos' },
  { id: 'LBY', name: 'Libya' },
  { id: 'JOR', name: 'Jordan' },
  { id: 'PRY', name: 'Paraguay' },
  { id: 'TGO', name: 'Togo' },
  { id: 'PNG', name: 'Papua New Guinea' },
  { id: 'SLV', name: 'El Salvador' },
  { id: 'NIC', name: 'Nicaragua' },
  { id: 'ERI', name: 'Eritrea' },
  { id: 'DNK', name: 'Denmark' },
  { id: 'KGZ', name: 'Kyrgyzstan' },
  { id: 'SVK', name: 'Slovakia' },
  { id: 'FIN', name: 'Finland' },
  { id: 'SLE', name: 'Sierra Leone' },
  { id: 'ARE', name: 'United Arab Emirates' },
  { id: 'TKM', name: 'Turkmenistan' },
  { id: 'CAF', name: 'Central African Republic' },
  { id: 'SGP', name: 'Singapore' },
  { id: 'NOR', name: 'Norway' },
  { id: 'BIH', name: 'Bosnia and Herzegovina' },
  { id: 'GEO', name: 'Georgia' },
  { id: 'CRI', name: 'Costa Rica' },
  { id: 'HRV', name: 'Croatia' },
  { id: 'MDA', name: 'Moldova' },
  { id: 'NZL', name: 'New Zealand' },
  { id: 'IRL', name: 'Ireland' },
  { id: 'COG', name: '"Congo, Republic of the"' },
  { id: 'LBN', name: 'Lebanon' },
  { id: 'PRI', name: 'Puerto Rico' },
  { id: 'LBR', name: 'Liberia' },
  { id: 'ALB', name: 'Albania' },
  { id: 'LTU', name: 'Lithuania' },
  { id: 'URY', name: 'Uruguay' },
  { id: 'PAN', name: 'Panama' },
  { id: 'MRT', name: 'Mauritania' },
  { id: 'MNG', name: 'Mongolia' },
  { id: 'OMN', name: 'Oman' },
  { id: 'ARM', name: 'Armenia' },
  { id: 'JAM', name: 'Jamaica' },
  { id: 'KWT', name: 'Kuwait' },
  { id: 'PSE', name: 'West Bank' },
  { id: 'LVA', name: 'Latvia' },
  { id: 'NAM', name: 'Namibia' },
  { id: 'MKD', name: 'Macedonia' },
  { id: 'BWA', name: 'Botswana' },
  { id: 'SVN', name: 'Slovenia' },
  { id: 'LSO', name: 'Lesotho' },
  { id: 'GMB', name: '"Gambia, The"' },
  { id: 'KWT', name: 'Kosovo' },
  { id: '149', name: 'Gaza Strip' },
  { id: 'GNB', name: 'Guinea-Bissau' },
  { id: 'GAB', name: 'Gabon' },
  { id: 'SWZ', name: 'Swaziland' },
  { id: '153', name: 'Mauritius' },
  { id: 'EST', name: 'Estonia' },
  { id: 'TTO', name: 'Trinidad and Tobago' },
  { id: 'TLS', name: 'Timor-Leste' },
  { id: 'CYP', name: 'Cyprus' },
  { id: 'FJI', name: 'Fiji' },
  { id: 'QAT', name: 'Qatar' },
  { id: '160', name: 'Comoros' },
  { id: 'GUY', name: 'Guyana' },
  { id: 'DJI', name: 'Djibouti' },
  { id: '163', name: 'Bahrain' },
  { id: 'BTN', name: 'Bhutan' },
  { id: 'MNE', name: 'Montenegro' },
  { id: 'GNQ', name: 'Equatorial Guinea' },
  { id: 'SLB', name: 'Solomon Islands' },
  { id: '168', name: 'Macau' },
  { id: '169', name: 'Cape Verde' },
  { id: 'LUX', name: 'Luxembourg' },
  { id: 'ESH', name: 'Western Sahara' },
  { id: 'SUR', name: 'Suriname' },
  { id: '173', name: 'Malta' },
  { id: '174', name: 'Maldives' },
  { id: 'BRN', name: 'Brunei' },
  { id: 'BLZ', name: 'Belize' },
  { id: 'BHS', name: '"Bahamas, The"' },
  { id: 'ISL', name: 'Iceland' },
  { id: '179', name: 'French Polynesia' },
  { id: '180', name: 'Barbados' },
  { id: '181', name: 'Mayotte' },
  { id: 'NCL', name: 'New Caledonia' },
  { id: '183', name: 'Netherlands Antilles' },
  { id: 'VUT', name: 'Vanuatu' },
  { id: '185', name: 'Samoa' },
  { id: '186', name: 'Sao Tome and Principe' },
  { id: '187', name: 'Saint Lucia' },
  { id: '188', name: 'Tonga' },
  { id: '189', name: 'Virgin Islands' },
  { id: '190', name: 'Grenada' },
  { id: '191', name: '"Micronesia, Federated States of"' },
  { id: '192', name: 'Aruba' },
  { id: '193', name: 'Saint Vincent and the Grenadines' },
  { id: '194', name: 'Kiribati' },
  { id: '195', name: 'Jersey' },
  { id: '196', name: 'Seychelles' },
  { id: '197', name: 'Antigua and Barbuda' },
  { id: '198', name: 'Andorra' },
  { id: '199', name: 'Isle of Man' },
  { id: 'DOM', name: 'Dominica' },
  { id: '201', name: 'Bermuda' },
  { id: '202', name: 'American Samoa' },
  { id: '203', name: 'Marshall Islands' },
  { id: '204', name: 'Guernsey' },
  { id: 'GRL', name: 'Greenland' },
  { id: '206', name: 'Cayman Islands' },
  { id: '207', name: 'Saint Kitts and Nevis' },
  { id: '208', name: 'Faroe Islands' },
  { id: '209', name: 'Northern Mariana Islands' },
  { id: '210', name: 'Liechtenstein' },
  { id: '211', name: 'San Marino' },
  { id: '212', name: 'Monaco' },
  { id: '213', name: 'Saint Martin' },
  { id: '214', name: 'Gibraltar' },
  { id: '215', name: 'British Virgin Islands' },
  { id: '216', name: 'Turks and Caicos Islands' },
  { id: '217', name: 'Palau' },
  { id: '218', name: 'Akrotiri' },
  { id: '219', name: 'Dhekelia' },
  { id: '220', name: 'Wallis and Futuna' },
  { id: '221', name: 'Anguilla' },
  { id: '222', name: 'Nauru' },
  { id: '223', name: 'Cook Islands' },
  { id: '224', name: 'Tuvalu' },
  { id: '225', name: '"Saint Helena, Ascension, and Tristan da Cunha"' },
  { id: '226', name: 'Saint Barthelemy' },
  { id: '227', name: 'Saint Pierre and Miquelon' },
  { id: '228', name: 'Montserrat' },
  { id: 'FLK', name: 'Falkland Islands (Islas Malvinas)' },
  { id: '230', name: 'Norfolk Island' },
  { id: '231', name: 'Svalbard' },
  { id: '232', name: 'Christmas Island' },
  { id: '233', name: 'Tokelau' },
  { id: '234', name: 'Niue' },
  { id: '235', name: 'Holy See (Vatican City)' },
  { id: '236', name: 'Cocos (Keeling) Islands' },
  { id: '237', name: 'Pitcairn Islands' },
  { id: 'ATA', name: 'Antarctica' },
  { id: 'ATF', name: 'French Southern and Antarctic Lands' },
  { id: 'SDS', name: 'South Sudan' },
  { id: 'ABV', name: 'Somaliland' },
  { id: 'OSA', name: 'Kosovo' }
];

export const CoreCurrencies = [
  { name: 'Albania Lek', code: 'ALL', symbol: 'Lek' },
  { name: 'Afghanistan Afghani', code: 'AFN', symbol: '؋' },
  { name: 'Argentina Peso', code: 'ARS', symbol: '$' },
  { name: 'Aruba Guilder', code: 'AWG', symbol: 'ƒ' },
  { name: 'Australia Dollar', code: 'AUD', symbol: '$' },
  { name: 'Azerbaijan Manat', code: 'AZN', symbol: '₼' },
  { name: 'Bahamas Dollar', code: 'BSD', symbol: '$' },
  { name: 'Barbados Dollar', code: 'BBD', symbol: '$' },
  { name: 'Belarus Ruble', code: 'BYN', symbol: 'Br' },
  { name: 'Belize Dollar', code: 'BZD', symbol: 'BZ$' },
  { name: 'Bermuda Dollar', code: 'BMD', symbol: '$' },
  { name: 'Bolivia Bolíviano', code: 'BOB', symbol: '$b' },
  { name: 'Bosnia and Herzegovina Convertible Mark', code: 'BAM', symbol: 'KM' },
  { name: 'Botswana Pula', code: 'BWP', symbol: 'P' },
  { name: 'Bulgaria Lev', code: 'BGN', symbol: 'лв' },
  { name: 'Brazil Real', code: 'BRL', symbol: 'R$' },
  { name: 'Brunei Darussalam Dollar', code: 'BND', symbol: '$' },
  { name: 'Cambodia Riel', code: 'KHR', symbol: '៛' },
  { name: 'Canada Dollar', code: 'CAD', symbol: '$' },
  { name: 'Cayman Islands Dollar', code: 'KYD', symbol: '$' },
  { name: 'Chile Peso', code: 'CLP', symbol: '$' },
  { name: 'China Yuan Renminbi', code: 'CNY', symbol: '¥' },
  { name: 'Colombia Peso', code: 'COP', symbol: '$' },
  { name: 'Costa Rica Colon', code: 'CRC', symbol: '₡' },
  { name: 'Croatia Kuna', code: 'HRK', symbol: 'kn' },
  { name: 'Cuba Peso', code: 'CUP', symbol: '₱' },
  { name: 'Czech Republic Koruna', code: 'CZK', symbol: 'Kč' },
  { name: 'Denmark Krone', code: 'DKK', symbol: 'kr' },
  { name: 'Dominican Republic Peso', code: 'DOP', symbol: 'RD$' },
  { name: 'East Caribbean Dollar', code: 'XCD', symbol: '$' },
  { name: 'Egypt Pound', code: 'EGP', symbol: '£' },
  { name: 'El Salvador Colon', code: 'SVC', symbol: '$' },
  { name: 'Euro Member Countries', code: 'EUR', symbol: '€' },
  { name: 'Falkland Islands (Malvinas) Pound', code: 'FKP', symbol: '£' },
  { name: 'Fiji Dollar', code: 'FJD', symbol: '$' },
  { name: 'Ghana Cedi', code: 'GHS', symbol: '¢' },
  { name: 'Gibraltar Pound', code: 'GIP', symbol: '£' },
  { name: 'Guatemala Quetzal', code: 'GTQ', symbol: 'Q' },
  { name: 'Guernsey Pound', code: 'GGP', symbol: '£' },
  { name: 'Guyana Dollar', code: 'GYD', symbol: '$' },
  { name: 'Honduras Lempira', code: 'HNL', symbol: 'L' },
  { name: 'Hong Kong Dollar', code: 'HKD', symbol: '$' },
  { name: 'Hungary Forint', code: 'HUF', symbol: 'Ft' },
  { name: 'Iceland Krona', code: 'ISK', symbol: 'kr' },
  { name: 'India Rupee', code: 'INR', symbol: '₹' },
  { name: 'Indonesia Rupiah', code: 'IDR', symbol: 'Rp' },
  { name: 'Iran Rial', code: 'IRR', symbol: '﷼' },
  { name: 'Isle of Man Pound', code: 'IMP', symbol: '£' },
  { name: 'Israel Shekel', code: 'ILS', symbol: '₪' },
  { name: 'Jamaica Dollar', code: 'JMD', symbol: 'J$' },
  { name: 'Japan Yen', code: 'JPY', symbol: '¥' },
  { name: 'Jersey Pound', code: 'JEP', symbol: '£' },
  { name: 'Kazakhstan Tenge', code: 'KZT', symbol: 'лв' },
  { name: 'Korea (North) Won', code: 'KPW', symbol: '₩' },
  { name: 'Korea (South) Won', code: 'KRW', symbol: '₩' },
  { name: 'Kyrgyzstan Som', code: 'KGS', symbol: 'лв' },
  { name: 'Laos Kip', code: 'LAK', symbol: '₭' },
  { name: 'Lebanon Pound', code: 'LBP', symbol: '£' },
  { name: 'Liberia Dollar', code: 'LRD', symbol: '$' },
  { name: 'Macedonia Denar', code: 'MKD', symbol: 'ден' },
  { name: 'Malaysia Ringgit', code: 'MYR', symbol: 'RM' },
  { name: 'Mauritius Rupee', code: 'MUR', symbol: '₨' },
  { name: 'Mexico Peso', code: 'MXN', symbol: '$' },
  { name: 'Mongolia Tughrik', code: 'MNT', symbol: '₮' },
  { name: 'Moroccan-dirham', code: 'MNT', symbol: ' د.إ' },
  { name: 'Mozambique Metical', code: 'MZN', symbol: 'MT' },
  { name: 'Namibia Dollar', code: 'NAD', symbol: '$' },
  { name: 'Nepal Rupee', code: 'NPR', symbol: '₨' },
  { name: 'Netherlands Antilles Guilder', code: 'ANG', symbol: 'ƒ' },
  { name: 'New Zealand Dollar', code: 'NZD', symbol: '$' },
  { name: 'Nicaragua Cordoba', code: 'NIO', symbol: 'C$' },
  { name: 'Nigeria Naira', code: 'NGN', symbol: '₦' },
  { name: 'Norway Krone', code: 'NOK', symbol: 'kr' },
  { name: 'Oman Rial', code: 'OMR', symbol: '﷼' },
  { name: 'Pakistan Rupee', code: 'PKR', symbol: '₨' },
  { name: 'Panama Balboa', code: 'PAB', symbol: 'B/.' },
  { name: 'Paraguay Guarani', code: 'PYG', symbol: 'Gs' },
  { name: 'Peru Sol', code: 'PEN', symbol: 'S/.' },
  { name: 'Philippines Peso', code: 'PHP', symbol: '₱' },
  { name: 'Poland Zloty', code: 'PLN', symbol: 'zł' },
  { name: 'Qatar Riyal', code: 'QAR', symbol: '﷼' },
  { name: 'Romania Leu', code: 'RON', symbol: 'lei' },
  { name: 'Russia Ruble', code: 'RUB', symbol: '₽' },
  { name: 'Saint Helena Pound', code: 'SHP', symbol: '£' },
  { name: 'Saudi Arabia Riyal', code: 'SAR', symbol: '﷼' },
  { name: 'Serbia Dinar', code: 'RSD', symbol: 'Дин.' },
  { name: 'Seychelles Rupee', code: 'SCR', symbol: '₨' },
  { name: 'Singapore Dollar', code: 'SGD', symbol: '$' },
  { name: 'Solomon Islands Dollar', code: 'SBD', symbol: '$' },
  { name: 'Somalia Shilling', code: 'SOS', symbol: 'S' },
  { name: 'South Korean Won', code: 'KRW', symbol: '₩' },
  { name: 'South Africa Rand', code: 'ZAR', symbol: 'R' },
  { name: 'Sri Lanka Rupee', code: 'LKR', symbol: '₨' },
  { name: 'Sweden Krona', code: 'SEK', symbol: 'kr' },
  { name: 'Switzerland Franc', code: 'CHF', symbol: 'CHF' },
  { name: 'Suriname Dollar', code: 'SRD', symbol: '$' },
  { name: 'Syria Pound', code: 'SYP', symbol: '£' },
  { name: 'Taiwan New Dollar', code: 'TWD', symbol: 'NT$' },
  { name: 'Thailand Baht', code: 'THB', symbol: '฿' },
  { name: 'Trinidad and Tobago Dollar', code: 'TTD', symbol: 'TT$' },
  { name: 'Turkey Lira', code: 'TRY', symbol: '₺' },
  { name: 'Tuvalu Dollar', code: 'TVD', symbol: '$' },
  { name: 'Ukraine Hryvnia', code: 'UAH', symbol: '₴' },
  { name: 'UAE-Dirham', code: 'AED', symbol: ' د.إ' },
  { name: 'United Kingdom Pound', code: 'GBP', symbol: '£' },
  { name: 'United States Dollar', code: 'USD', symbol: '$' },
  { name: 'Uruguay Peso', code: 'UYU', symbol: '$U' },
  { name: 'Uzbekistan Som', code: 'UZS', symbol: 'лв' },
  { name: 'Venezuela Bolívar', code: 'VEF', symbol: 'Bs' },
  { name: 'Viet Nam Dong', code: 'VND', symbol: '₫' },
  { name: 'Yemen Rial', code: 'YER', symbol: '﷼' },
  { name: 'Zimbabwe Dollar', code: 'ZWD', symbol: 'Z$' },
];

export const NodeFields = [
  { key: '', value: '' },
  { key: 'name', value: 'Name' },
  { key: 'reference', value: 'Reference' },
  { key: 'crossReference', value: 'Cross Reference' },
  { key: 'level', value: 'Level' },
  { key: 'positionX', value: 'X position' },
  { key: 'id', value: 'Id' },
  { key: 'status', value: 'Status' },
  { key: 'responsibleId', value: 'Responsible' },
  { key: 'documentUri', value: 'Document URL' },
  { key: 'businesscalculation', value: 'Business calculation' },
  { key: 'storypoints', value: 'Story points' },
  { key: 'nodeType', value: 'Node-type' },
  { key: 'startDate', value: 'Start Date' },
  { key: 'targetDate', value: 'Planned End Date' },
  { key: 'budget', value: 'Target' },
  { key: 'budgetCalculated', value: 'Current' },
  { key: 'benefitBudget', value: 'Benefit budget' },
  { key: 'benefitActual', value: 'Benefit actual' },
  { key: 'benefitRemaining', value: 'Benefit remaining' },
  { key: 'costBudget', value: 'OPEX budget' },
  { key: 'costActual', value: 'OPEX actual' },
  { key: 'costRemaining', value: 'OPEX remaining' },
  { key: 'investBudget', value: 'CAPEX budget' },
  { key: 'investActual', value: 'CAPEX actual' },
  { key: 'investRemaining', value: 'CAPEX remaining' },
  { key: 'businessBenefit', value: 'Importance' },
  { key: 'uncertainty', value: 'Uncertainty' },
  { key: 'risk', value: 'Risk' },
  { key: 'sizeType', value: 'Size' },
  { key: 'complexity', value: 'Complexity' },
  { key: 'description', value: 'Description' },
  { key: 'description1', value: 'Control' },
  { key: 'description2', value: 'Input' },
  { key: 'description3', value: 'Output' },
  { key: 'description4', value: 'Mechanisms' },
  { key: 'unused', value: 'Unused' },
  { key: 'workFlowModel', value: 'Workflow model' },
  { key: 'workFlowOperation', value: 'Workflow operation' },
  { key: 'workFlowIfClause', value: 'Workflow if clauses' },
  { key: 'workFlowId', value: 'Workflow identifier' },
  { key: 'workFlowFormId', value: 'Workflow form identifier' },
  { key: 'workFlowLink', value: 'Workflow link' },
  { key: 'workFlowFormParams', value: 'Workflow form params' },
  { key: 'workFlowTabId', value: 'Questionnaire tab identifier' },
  { key: 'formId', value: 'Form id' },
  { key: 'formFieldId', value: 'Form field id' },
  { key: 'formFieldControlType', value: 'Form field control type' },
  { key: 'formFieldType', value: 'Form field type' },
  { key: 'formFieldCalculation', value: 'Form calculations' },
  { key: 'formBucket', value: 'Store in bucket' },
  { key: 'formFieldDropdownValue', value: 'Form field dropdown value' },
  { key: 'creationDate', value: 'Creation date' },
  { key: 'lastUpdated', value: 'Last updated' },
  { key: 'currency', value: 'Currency' },
  { key: 'upload_uri', value: 'Documen Link' },
  { key: 'actualDate', value: 'Actual End Date' },
  { key: 'actualStartDate', value: 'Actual Start Date' },
  { key: 'percentageComplete', value: 'Percentage Completed' },
  { key: 'priority', value: 'Priority' },
  { key: 'active', value: 'Active' },
  { key: 'onreport', value: 'Show on reports' },
  { key: 'riskProfileCategory', value: 'Risk Profile Category' },
  { key: 'riskProfileAssessment', value: 'Risk Profile Assessment' },
  { key: 'riskProfileCountermeasures', value: 'Risk Profile Countermeasures' },
  { key: 'time', value: 'Approach vs. Project' },
  { key: 'timeDescription', value: 'Time Description' },
  { key: 'cost', value: 'Cost' },
  { key: 'costDescription', value: 'Cost Description' },
  { key: 'quality', value: 'Quality' },
  { key: 'qualityDescription', value: 'Quality Description' },
  { key: 'executive', value: 'Executive' },
  { key: 'executiveDescription', value: 'Executive Description' },
  { key: 'program', value: 'Program/ project' },
  { key: 'programDescription', value: 'Program/ project Description' },
  { key: 'functional', value: 'Functional' },
  { key: 'functionalDescription', value: 'Functional Description' },
  { key: 'resource', value: 'Resource' },
  { key: 'resourceDescription', value: 'Resource Description' },
  { key: 'organizational', value: 'Organizational' },
  { key: 'organizationalDescription', value: 'Organizational Description' },
  { key: 'technical', value: 'Technical' },
  { key: 'technicalDescription', value: 'Technical Description' },
  { key: 'costDescription', value: 'Question' },
  { key: 'uncertainty', value: 'Workflow answers' },
  { key: 'children', value: 'Children' },
  { key: 'color', value: 'Color' },
  { key: 'icon', value: 'Icon' },
  { key: 'widget', value: 'Widget' },
  { key: 'formFieldEditable', value: 'Form field editable' },
  { key: 'formFieldSortable', value: 'Form field sortable' },
  { key: 'formFieldFilterable', value: 'Form field filterable' },
  { key: 'formFieldFixed', value: 'Form field fixed' },
  { key: 'formFieldSearchable', value: 'Form field searchable' },
  { key: 'formFieldWidth', value: 'Form field width' },
  { key: 'formFieldBucketId', value: 'Bucket id' },
  { key: 'obligatory', value: 'Obligatory' },
  { key: 'first_name', value: 'First name' },
  { key: 'last_name', value: 'Last name' },
  { key: 'email', value: 'Email' },
  { key: 'image', value: 'Image' },
  { key: 'weight', value: 'Weight' },
  { key: 'createdAt', value: 'Created at' },
  { key: 'form_default_value', value: 'Form default value' },
  { key: 'formFieldShape', value: 'Form field shape' },
  { key: 'ai_type', value: 'AI type' },
  { key: 'ai_preset', value: 'AI preset' },
  { key: 'commercialStatus', value: 'Commercial status' },
  { key: 'fullscreen', value: 'Fullscreen' },
  { key: 'printable', value: 'Printable' },
  { key: 'hideWidget', value: 'Hide widget initially' },
  { key: 'sorting', value: 'Sorting' },
  { key: 'dashboardCols', value: 'Dashboard cols' },
  { key: 'dashboardRows', value: 'Dashboard rows' },
  { key: 'dashboardX', value: 'Dashboard x' },
  { key: 'dashboardY', value: 'Dashboard y' },
  { key: 'start', value: 'Start Date (start)' },
  { key: 'end', value: 'End Date (end)' },
  { key: 'fieldConversion', value: 'Conversion' },
  { key: 'fieldCreateType', value: 'Create type' },
  { key: 'fieldSkipIfExists', value: 'Skip if exists' },
  { key: 'colorLabelProvider', value: 'Color label providers' },
  { key: 'alsoPublishedAs', value: 'Also published as' },
  { key: 'cpc', value: 'CPC' },
  { key: 'cpc4', value: 'CPC4' },
  { key: 'ipc', value: 'IPC' },
  { key: 'ipc4', value: 'IPC4' },
  { key: 'applicants', value: 'Applicants' },
  { key: 'techAreas', value: 'Tech areas' },
  { key: 'techFields', value: 'Tech fields' },
  { key: 'authorities', value: 'Authorities' },
  { key: 'numberField1', value: 'Number field 1' },
  { key: 'numberField2', value: 'Number field 2' },
  { key: 'numberField3', value: 'Number field 3' },
  { key: 'numberField4', value: 'Number field 4' },
  { key: 'numberField5', value: 'Number field 5' },
  { key: 'numberField6', value: 'Number field 6' },
  { key: 'numberField7', value: 'Number field 7' },
  { key: 'numberField8', value: 'Number field 8' },
  { key: 'numberField9', value: 'Number field 9' },
  { key: 'numberField10', value: 'Number field 10' },
  { key: 'textField1', value: 'Text field 1' },
  { key: 'textField2', value: 'Text field 2' },
  { key: 'textField3', value: 'Text field 3' },
  { key: 'textField4', value: 'Text field 4' },
  { key: 'textField5', value: 'Text field 5' },
  { key: 'textField6', value: 'Text field 6' },
  { key: 'textField7', value: 'Text field 7' },
  { key: 'textField8', value: 'Text field 8' },
  { key: 'textField9', value: 'Text field 9' },
  { key: 'textField10', value: 'Text field 10' },
  { key: 'textField11', value: 'Text field 11' },
  { key: 'textField12', value: 'Text field 12' },
  { key: 'textField13', value: 'Text field 13' },
  { key: 'textField14', value: 'Text field 14' },
  { key: 'textField15', value: 'Text field 15' },
  { key: 'textField16', value: 'Text field 16' },
  { key: 'textField17', value: 'Text field 17' },
  { key: 'textField18', value: 'Text field 18' },
  { key: 'textField19', value: 'Text field 19' },
  { key: 'textField20', value: 'Text field 20' },
  { key: 'textField21', value: 'Text field 21' },
  { key: 'textField22', value: 'Text field 22' },
  { key: 'textField23', value: 'Text field 23' },
  { key: 'textField24', value: 'Text field 24' },
  { key: 'textField25', value: 'Text field 25' },
  { key: 'textField26', value: 'Text field 26' },
  { key: 'textField27', value: 'Text field 27' },
  { key: 'textField28', value: 'Text field 28' },
  { key: 'textField29', value: 'Text field 29' },
  { key: 'textField30', value: 'Text field 30' },
  { key: 'textField31', value: 'Text field 31' },
  { key: 'textField32', value: 'Text field 32' },
  { key: 'textField33', value: 'Text field 33' },
  { key: 'textField34', value: 'Text field 34' },
  { key: 'textField35', value: 'Text field 35' },
  { key: 'textField36', value: 'Text field 36' },
  { key: 'textField37', value: 'Text field 37' },
  { key: 'textField38', value: 'Text field 38' },
  { key: 'textField39', value: 'Text field 39' },
  { key: 'textField40', value: 'Text field 40' },
  { key: 'formFieldValidation', value: 'Form field validation' },
  { key: 'formFieldValidationMessage', value: 'Form field validation message' },
  { key: 'bestCase', value: 'Best case scenario' },
  { key: 'aiService', value: 'AI Service' },
  { key: 'externalInput', value: 'External Input' },
  { key: 'code', value: 'Code' },
  { key: 'dynamic-reminder', value: 'Dynamic: Reminder' },
  { key: 'dynamic-datestack', value: 'Dynamic: Date range' },
  { key: 'dynamic-comments', value: 'Dynamic: Comments' },
  { key: 'dynamic-gantt', value: 'Dynamic: Gantt' },
  { key: 'dynamic-sending-site', value: 'Dynamic: Sending site' },
  { key: 'dynamic-receiving-site', value: 'Dynamic: Receiving site' },
  { key: 'dynamic-amount', value: 'Dynamic: Amount' },
  { key: 'dynamic-expand', value: 'Dynamic: Expand' },
  { key: 'dynamic-machine-type', value: 'Dynamic: Machine type' },
  { key: 'dynamic-line', value: 'Dynamic: Line' },
  { key: 'dynamic-children', value: 'Dynamic: Children' },
  { key: 'dynamic-parents', value: 'Dynamic: Parents' },
  { key: 'dynamic-assign', value: 'Dynamic: Assign' },
  { key: 'dynamic-count', value: 'Dynamic: Count' },
  { key: 'dynamic-transfer', value: 'Dynamic: Transfer' },
  { key: 'dynamic-reassign', value: 'Dynamic: Re-Assign' },
  { key: 'dynamic-as-user', value: 'Dynamic: As user' },
  { key: 'dynamic-has-access', value: 'Dynamic: Has access' },
  { key: 'dynamic-connect', value: 'Dynamic: Connect' },
  { key: 'dynamic-delete', value: 'Dynamic: Delete' },
  { key: 'dynamic-previous', value: 'Dynamic: Previous' },
  { key: 'dynamic-next', value: 'Dynamic: Next' },
  { key: 'floatField1', value: 'Float field 1' },
  { key: 'floatField2', value: 'Float field 2' },
  { key: 'floatField3', value: 'Float field 3' },
  { key: 'floatField4', value: 'Float field 4' },
  { key: 'floatField5', value: 'Float field 5' },
  { key: 'floatField6', value: 'Float field 6' },
  { key: 'floatField7', value: 'Float field 7' },
  { key: 'floatField8', value: 'Float field 8' },
  { key: 'floatField9', value: 'Float field 9' },
  { key: 'floatField10', value: 'Float field 10' },
  { key: 'showOnlyOnMobile', value: 'Show only on mobile' },
  { key: 'hideOnMobile', value: 'Hide on mobile' },
  { key: 'partOfTRT', value: 'Part of TRT' },
  { key: 'groupId', value: 'Groups' }
];

export const FormFieldControlType = [
  { key: '', value: '' },
  { key: 'textbox', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.TEXTBOX' },
  { key: 'dropdown', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.DROPDOWN' },
  { key: 'checkbox', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.CHECKBOX' },
  { key: 'text', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.TEXT' },
  { key: 'textbox-button', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.TEXTBOXBUTTON' },
  { key: 'date', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.DATE' },
  { key: 'daterange', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.DATERANGE' },
  { key: 'color', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.COLOR' },
  { key: 'children', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.CHILDREN' },
  { key: 'parents', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.PARENTS' },
  { key: 'calculated', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.CALCULATED' },
  { key: 'sublevel', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.SUBLEVEL' },
  { key: 'sublevelWithName', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.SUBLEVELWITHNAME' },
  { key: 'image', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.IMAGE' },
  { key: 'formemail', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.FORMEMAIL' },
  { key: 'entities', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.ENTITIES' },
  { key: 'delete', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.DELETE' },
  { key: 'file-status', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.FILESTATUS' },
  { key: 'priority-score', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.PRIORITYSCORE' },
  { key: 'table', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.TABLE' },
  { key: 'capacity', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.CAPACITY' },
  { key: 'select', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.SELECT' },
  { key: 'violatedParagraph', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.VIOLATEDPARAGRAPH' },
  { key: 'questionnaire', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.QUESTIONNAIRE' },
  { key: 'hidden', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.HIDDEN' },
  { key: 'lastEditedBy', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.LASTEDITEDBY' },
  { key: 'validatedBy', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.VALIDATEDBY' },
  { key: 'lastEditedWhat', value: 'Last Edited what' },
  { key: 'lastEditedWhen', value: 'Last Edited at' },
  { key: 'createdAt', value: 'Created at' },
  { key: 'createdBy', value: 'Created by' },
  { key: 'history', value: 'History' },
  { key: 'reminder', value: 'Reminder' },
  { key: 'datestack', value: 'Date stack' },
  { key: 'comments', value: 'Comments' },
  { key: 'gantt', value: 'Gantt' },
  { key: 'date-quarter', value: 'Date: Quarter select' },
  { key: 'node-select', value: 'Node(s) select' },
  { key: 'action-buttons', value: 'Action buttons' },
  { key: 'aws-s3-bucket', value: 'AWS S3 Bucket' },
  { key: 'assign', value: 'Assign to elements' },
  { key: 'colorlabelprovider', value: 'Color label provider' },
  { key: 'count', value: 'Count' },
  { key: 'standard-configurations', value: 'Standard configurations' },
  { key: 'add', value: 'Add' },
  { key: 'children-sum', value: 'Children Sum' },
  { key: 'parent-sum', value: 'Parent Sum' },
  { key: 'children-status', value: 'Children status' },
  { key: 'start-date', value: 'Start Date' },
  { key: 'target-date', value: 'Target Date' },
  { key: 'completeness', value: 'Completeness' },
  { key: 'boxes', value: 'Boxes' },
  { key: 'due-date', value: 'Due date' },
  { key: 'highlight', value: 'Highlight' },
  { key: 'toggle', value: 'Toggle' },
  { key: 'yearCalendar', value: 'Year calendar' },
  { key: 'answer-select', value: 'Answer(s) select' },
  { key: 'text-marker', value: 'Text Marker' },
  { key: 'odin-factsheet', value: 'Odin Factsheet' },
  { key: 'checkChildren', value: 'Check children' },
  { key: 'createNodes', value: 'Create nodes' },
  { key: 'seasonalDistribution', value: 'Seasonal distribution' },
  { key: 'formPreFunction', value: 'Form Prefunction' },
  { key: 'connect', value: 'Connect' },
  { key: 'textbox-with-activities', value: 'Textbox with activities' },
  { key: 'connected-values', value: 'Values from connected nodes' },
  { key: 'separator', value: 'Separator' },
  { key: 'elements-dnd', value: 'Elements drag & drop' },
  { key: 'buckets-sumup', value: 'Buckets: Sum up' },
  { key: 'cw', value: 'Calendar week' },
  { key: 'document', value: 'Document' },
  { key: 'coefficient', value: 'Coefficient' },
  { key: 'code', value: 'Code' },
  { key: 'button', value: 'Button' },
  { key: 'calculation', value: 'Calculation' },
  { key: 'distribution', value: 'Distribution' },
  { key: 'related', value: 'Show related node' },
  { key: 'check-by-llm', value: 'Check by LLM' },
  { key: 'aggregation', value: 'Aggregation' }
];

export const FormFieldType = [
  { key: '', value: '' },
  { key: 'string', value: 'NODE.DATASHEET.FIELDS.FORMFIELDTYPE.TEXT' },
  { key: 'number', value: 'NODE.DATASHEET.FIELDS.FORMFIELDTYPE.NUMBER' },
  { key: 'nodes', value: 'NODE.DATASHEET.FIELDS.FORMFIELDTYPE.NODES' },
  { key: 'year', value: 'NODE.DATASHEET.FIELDS.FORMFIELDTYPE.YEAR' },
  { key: 'quarter', value: 'NODE.DATASHEET.FIELDS.FORMFIELDTYPE.QUARTER' },
  { key: 'email', value: 'NODE.DATASHEET.FIELDS.FORMFIELDTYPE.EMAIL' },
];

export const FormFieldShape = [
  { key: '', value: '' },
  { key: 'circle', value: 'Circle' },
  { key: 'triangle', value: 'Triangle' },
  { key: 'rectangle', value: 'Rectangle' },
  { key: 'text-align-center', value: 'Text align center' },
  { key: 'text-align-right', value: 'Text align right' },
  { key: 'no-flex', value: 'No flex box' },
  { key: 'flex-tl', value: 'Flex: Top left' },
  { key: 'flex-tc', value: 'Flex: Top center' },
  { key: 'flex-tr', value: 'Flex: Top right' },
  { key: 'flex-cl', value: 'Flex: Center left' },
  { key: 'flex-cl', value: 'Flex: Center left' },
  { key: 'flex-cc', value: 'Flex: Center center' },
  { key: 'flex-cr', value: 'Flex: Center right' },
  { key: 'flex-bl', value: 'Flex: Bottom left' },
  { key: 'flex-bc', value: 'Flex: Bottom center' },
  { key: 'flex-br', value: 'Flex: Bottom right' },
  { key: 'no-null', value: 'Dropdown: No null' },
  { key: 'chart', value: 'Chart' },
];

export const FormFieldCalculation = [
  { key: '', value: '' },
  { key: 'roi', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCALCULATION.ROI' },
  { key: 'strategicclassification', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCALCULATION.STRATEGICCLASSIFICATION' },
  { key: 'challenge', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCALCULATION.CHALLENGE' },
  { key: 'challenge2nd', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCALCULATION.CHALLENGE2ND' },
  { key: 'aggregation', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCALCULATION.AGGREGATION' },
  { key: 'aggregationsub', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCALCULATION.AGGREGATIONSUB' },
  { key: 'average', value: 'Average' },
  { key: 'assignedcapacity', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCALCULATION.ASSIGNEDCAPACITY' },
  { key: 'line-design', value: 'Line design' },
  { key: 'traverser', value: 'Traverser' },
  { key: 'calculateCO2Scope3_3', value: 'Calculate CO2 Scope 3.3.' },
  { key: 'booked-human-resources', value: 'Booked human resources' },
  { key: 'create-capacity-by-default', value: 'Create capacity by default' },
  { key: 'sum', value: 'Sum up the values' },
  { key: 'best-case', value: 'Best case' },
  { key: 'worst-case', value: 'Worst case' },
  { key: 'count', value: 'Count' },
];

export const FormFieldDropdownValue = [
  { key: '', value: '' },
  { key: 'humanresources', value: 'NODE.DATASHEET.FIELDS.FORMFIELDDROPDOWNVALUE.HUMANRESOURCES' },
  { key: 'groups', value: 'NODE.DATASHEET.FIELDS.FORMFIELDDROPDOWNVALUE.GROUPS' },
  { key: 'businesscalculations', value: 'NODE.DATASHEET.FIELDS.FORMFIELDDROPDOWNVALUE.BUSINESSCALCULATIONS' },
  { key: 'models', value: 'NODE.DATASHEET.FIELDS.FORMFIELDDROPDOWNVALUE.MODELS' },
  { key: 'nodes', value: 'NODE.DATASHEET.FIELDS.FORMFIELDDROPDOWNVALUE.NODES' },
  { key: 'level', value: 'Levels' },
  { key: 'status', value: 'NODE.DATASHEET.FIELDS.FORMFIELDDROPDOWNVALUE.STATUS' },
  { key: 'statuscolor', value: 'NODE.DATASHEET.FIELDS.FORMFIELDDROPDOWNVALUE.STATUSCOLOR' },
  { key: 'shape', value: 'NODE.DATASHEET.FIELDS.FORMFIELDDROPDOWNVALUE.SHAPE' },
  { key: 'humanresourceimages', value: 'NODE.DATASHEET.FIELDS.FORMFIELDDROPDOWNVALUE.HUMANRESOURCEIMAGES' },
  { key: 'markets', value: 'NODE.DATASHEET.FIELDS.FORMFIELDDROPDOWNVALUE.MARKETS' },
  { key: 'overbooked', value: 'NODE.DATASHEET.FIELDS.FORMFIELDDROPDOWNVALUE.OVERBOOKED' },
  { key: 'nodeType', value: 'Node type' },
  { key: 'colorLabelProviders', value: 'Color label providers' },
  { key: 'widget', value: 'Widget' },
  { key: 'responsible', value: 'Responsible' },
  { key: 'formFieldId', value: 'Form Field Id' },
  { key: 'formFieldControlType', value: 'Form field control type' },
  { key: 'formFieldType', value: 'Form field type' },
  { key: 'formFieldCalculation', value: 'Form field calculation' },
  { key: 'formFieldDropdownValue', value: 'Form field dropdown value' },
  { key: 'aiServices', value: 'AI services' },
  { key: 'externalInput', value: 'External input' }
];

export const AIServices = [
  { key: '', value: '' },
  { key: 'privategpt', value: 'Private GPT' },
  { key: 'autogen', value: 'Autogen' },
  { key: 'uknow', value: 'U-Know' }
];

export const ExternalInput = [
  { key: '', value: '' },
  { key: 'bookstack', value: 'Book Stack' }
];

export interface SequenceElement {
  label: string;
  icon: string;
  showLabel: boolean;
  showDot: boolean;
  showIcon: boolean;
  active: boolean;
  disabled: boolean;
  inactive: boolean;
  visible: boolean;
  dataSource: boolean;
  treeNode: TreeNode;
  style: string;
}

export const ModelTypes = [
  { key: MODEL_TYPE_DEFAULT, value: '' },
  { key: MODEL_TYPE_DATASOURCE, value: 'Data source' },
  { key: MODEL_TYPE_CCM, value: 'Client config map' },
  { key: MODEL_TYPE_SCM, value: 'Search config map' },
  { key: MODEL_TYPE_UCIM, value: 'Unique Consulting IP Map' },
  { key: MODEL_TYPE_CWM, value: 'Current working model' },
  { key: MODEL_TYPE_MCM, value: 'Module config map' },
  { key: MODEL_TYPE_LIBRARY, value: 'Library' },
  { key: MODEL_TYPE_SPLASH, value: 'Splash' },
  { key: MODEL_TYPE_STRATEGYMAP, value: 'Strategy map' },
  { key: MODEL_TYPE_PROJECTPORTFOLIO, value: 'Project Portfolio' },
  { key: MODEL_TYPE_NFM, value: 'Notifications' },
];

export interface TransferOptions {
  peripheryToUpdate?: PeripheryToUpdate[];
}

export interface PeripheryToUpdate {
  field: string;
  fieldNode: TreeNode;
  nodeType: number;
  modelId: string;
  target: string;
  conditionalTarget: TreeNode;
  distribution: boolean;
  traverser: Traverser;
}

export interface GetDocumentsRequest {
  keys: string[];
}

export interface GetDocumentsResponse {
  key: string;
  url: string;
  contentType: string;
  contentLength: number;
}

export const PHRASE = 'AUFzK62l67C6Tv5FFKS7OMZm2TrVRT';
export const CIPHERKEY = '6262828505528023';
export const CIPHERIV = '6262828505528023';
